import { Fragment, useMemo } from "react";
import { WMSTileLayer } from "react-leaflet";
import { useRasterQuantiles } from "../../hooks";
import { BELGIUM_GEOMETRY, QUANTILE_BINS } from "../../config";
import { SpatialOverlayer } from "../../types";
import { LatLngBounds } from "leaflet";
import { boundsAtom } from "../../atoms/bounds.atom";
import { useAtom } from "jotai";
import { latLngBoundsToWkt } from "../../utils/geometry.utils";
import {
  activeBelfiusOverlayerAtom,
  activeExtraInfoOverlayerAtom,
  activeDemographicOverlayerAtom,
  activeSpatialOverlayerAtom,
} from "../../atoms/overlayers.atom";
import { layerOpacityAtom } from "../../atoms/layerOpacity.atom";
import { isLegendRescaledAtom } from "../../atoms/legend.atom";
import { useRaster } from "../../hooks/useRaster";
import { useBelfiusQuantiles } from "../../hooks/useBelfiusQuantiles";

export const OverlayerWMS = () => {
  const [bounds] = useAtom(boundsAtom);
  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);
  const [activeDemographicOverlayers] = useAtom(activeDemographicOverlayerAtom);
  const [activeExtraInfoOverlayers] = useAtom(activeExtraInfoOverlayerAtom);
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);

  return render();

  function render() {
    if (!bounds) return null;

    return (
      <>
        {activeSpatialOverlayer && (
          <SpatialOverlayerOnMap
            key={activeSpatialOverlayer.key}
            overlayer={activeSpatialOverlayer}
            rescaledBounds={bounds}
            belfiusOverlayerKey={activeBelfiusOverlayer?.key}
          />
        )}

        {activeDemographicOverlayers && (
          <WMSTileLayer
            key={activeDemographicOverlayers.key}
            {...activeDemographicOverlayers.wmsLayer}
            url={activeDemographicOverlayers.wmsLayer.url}
            opacity={0.8}
            format="image/png"
            transparent
            params={{
              // @ts-ignore
              tiled: true,
            }}
          />
        )}

        {activeExtraInfoOverlayers.map((overlayer) => (
          <WMSTileLayer
            key={overlayer.name}
            {...overlayer.wmsLayer}
            url={overlayer.wmsLayer.url}
            opacity={0.8}
            zIndex={10}
          />
        ))}

        {activeBelfiusOverlayer && (
          <WMSTileLayer
            key={activeBelfiusOverlayer.key}
            {...activeBelfiusOverlayer.wmsLayer}
            layers={`${activeBelfiusOverlayer.wmsLayer.layers}`}
            url={activeBelfiusOverlayer.wmsLayer.url}
            opacity={0.9}
          />
        )}
      </>
    );
  }
};

const SpatialOverlayerOnMap = (props: {
  overlayer: SpatialOverlayer;
  rescaledBounds: LatLngBounds;
  belfiusOverlayerKey: string | undefined;
}) => {
  const { overlayer } = props;

  const [isLegendRescaled] = useAtom(isLegendRescaledAtom);
  const [layerOpacity] = useAtom(layerOpacityAtom);
  const [bounds] = useAtom(boundsAtom);
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);

  const { data: rasterDetails } = useRaster(overlayer.wmsLayer.layers);
  const { data: quantiles } = useRasterQuantiles(overlayer, QUANTILE_BINS, BELGIUM_GEOMETRY);
  const { data: quantilesForBoundArea } = useRasterQuantiles(
    overlayer,
    QUANTILE_BINS,
    latLngBoundsToWkt(bounds) as string,
    {
      enabled: !!bounds,
    }
  );
  const { data: belfiusQuantiles } = useBelfiusQuantiles(activeBelfiusOverlayer!, props.overlayer, {
    enabled: !!activeBelfiusOverlayer,
  });

  const env = useMemo(() => {
    if (!quantiles) return null;
    if (!quantilesForBoundArea) return null;
    if (!rasterDetails) return null;

    // if the legend is explicity rescale=true
    if (isLegendRescaled) {
      // show rescaled on the active BelfiusCluster
      if (activeBelfiusOverlayer && belfiusQuantiles) {
        return [
          `quantile0:${belfiusQuantiles[0].value}`,
          `quantile25:${belfiusQuantiles[1].value}`,
          `quantile50:${belfiusQuantiles[2].value}`,
          `quantile75:${belfiusQuantiles[3].value}`,
          `quantile100:${belfiusQuantiles[4].value}`,
        ].join(";");
      }
      // or rescale based on the bound area
      else {
        return [
          `quantile0:${quantilesForBoundArea[0].value}`,
          `quantile25:${quantilesForBoundArea[1].value}`,
          `quantile50:${quantilesForBoundArea[2].value}`,
          `quantile75:${quantilesForBoundArea[3].value}`,
          `quantile100:${quantilesForBoundArea[4].value}`,
        ].join(";");
      }
    }
    // else render based on the full map min/max, p25, p50, p75
    else {
      return [
        `quantile0:${rasterDetails.globalMin || 0}`,
        `quantile25:${quantiles[1].value}`,
        `quantile50:${quantiles[2].value}`,
        `quantile75:${quantiles[3].value}`,
        `quantile100:${rasterDetails.globalMax || 99999}`,
      ].join(";");
    }
  }, [quantiles, quantilesForBoundArea, rasterDetails, isLegendRescaled, activeBelfiusOverlayer, belfiusQuantiles]);

  return render();

  function render() {
    if (!env) return null;

    return (
      <WMSTileLayer
        key={[overlayer.key, env].join()}
        {...overlayer.wmsLayer}
        layers={"walkability:" + overlayer.wmsLayer.layers}
        url={overlayer.wmsLayer.url}
        opacity={layerOpacity}
        // @ts-ignore WMSTileLayer typings complains but there is nothing wrong here
        env={env}
      />
    );
  }
};
