import { useMap } from "react-leaflet";

export const MapSizeObserver = () => {
  const map = useMap();
  const ro = new ResizeObserver(() => {
    map.invalidateSize();
  });
  ro.observe(map.getContainer());
  return null;
};
