import { createInstance, RasterBody, RasterQuantiles, RasterQuantilesBody, RasterStats } from "guppy.ts";
import { LatLng } from "leaflet";

export const guppy = createInstance({
  url: "/guppy",
});

// TODO: this function should not exist, use native guppy.ts instance functions
export async function getRasterData(layer: string | null, latLng: LatLng) {
  if (!layer) {
    return undefined;
  }
  const config: RasterBody = {
    geometry: `POINT(${latLng.lng} ${latLng.lat})`,
    resolution: "auto",
    srs: "EPSG:4326",
  };
  const data = (await guppy.getRasterData(layer, config)) as number[];
  return data ? Math.round(data[0] * 100) / 100 : undefined;
}

// TODO: this function should not exist, use native guppy.ts instance functions
export async function getRasterStats(layerKey: string | undefined, geometry: string) {
  if (!layerKey) {
    return undefined;
  }
  const config: RasterBody = {
    srs: "EPSG:4326",
    geometry,
    resolution: "auto",
  };
  const stats: RasterStats = await guppy.getRasterStats(layerKey, config);
  if (typeof stats.min === "number" && typeof stats.max === "number") return stats;
  throw new Error("invalid stats recieved");
}

// TODO: this function should not exist, use native guppy.ts instance functions
export const fetchRasterQuantiles = async (layerKey: string | undefined, geometry: string, quantiles: number[]) => {
  if (!layerKey) {
    return undefined;
  }
  const config: RasterQuantilesBody = {
    quantiles,
    geometry,
    resolution: "auto",
    srs: "EPSG:4326",
  };
  const quantilesData: RasterQuantiles[] = await guppy.getRasterQuantiles(layerKey, config);
  return quantilesData;
};
