import { DialogContent, Typography } from "@material-ui/core";
import { InformationDialogTitle } from "../InformationDialog";

export function BelfiusClustersInformation() {
  return (
    <>
      <InformationDialogTitle>Meer info over de Belfius Cluster</InformationDialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          <strong>
            Het aanklikken van een Belfius-cluster laat je toe om jouw buurt te vergelijken met buurten in gelijkaardige
            gemeenten.
          </strong>
        </Typography>
        <Typography variant="body1" paragraph>
          <i>Wat is een Belfius-cluster?</i>
          <br />
          Belfius verdeelt alle gemeenten van het Vlaamse Gewest onder in zestien clusters. Die behoren tot zes types
          gemeenten: woongemeenten, landelijke gemeenten, gemeenten met aantrekking door economische activiteit,
          verstedelijkte gemeenten, grote en regionale steden en kustgemeenten. Dat is de zogenaamde Belfius-typologie.
          Omdat de walkabilityscore-tool ook berekend is voor het Brussels Hoofdstedelijk Gewest (BHG) is deze als extra
          cluster toegevoegd.
        </Typography>
        <Typography variant="body1" paragraph>
          <i>Hoe weet je in welke Belfius-cluster jouw buurt is gelegen?</i>
          <br />
          In het ANALYSE-INFO-venster (linksboven op de kaart) kan je, na een klik op de kaart, lezen tot welke
          Belfius-cluster de buurt behoort.
        </Typography>
        <Typography variant="body1" paragraph>
          <i>Zo activeer je de Belfius-cluster</i>
          <br />
          Onderaan links op je scherm vind je de BELFIUS-CLUSTERS. Nadat je een Belfius-cluster selecteert, toont de
          kaart enkel de gemeenten die horen tot de door jou geselecteerde Belfius-cluster (de andere gemeenten zijn
          bedekt met een grijze kleur). Check zeker even of de buurt die je aanklikte zichtbaar is. Als dat niet zo is,
          selecteerde je per ongeluk een andere cluster. Zie je niets veranderen, zoom dan even in en uit.
        </Typography>
        <Typography variant="body1" paragraph>
          <i>Wil je jouw buurt vergelijken met buurten uit gelijkaardige gemeenten?</i>
          <br />
          Activeer dan de HERSCHAAL-functie in het LEGENDE-venster. Jouw buurt wordt nu vergeleken met alle andere
          buurten uit dezelfde Belfius-cluster. Hoe dit precies in zijn werk gaat, lees je in het ‘Meer info’-venster
          over de Walkabilityscore.
        </Typography>
      </DialogContent>
    </>
  );
}
