import { Tooltip, IconButton } from "@material-ui/core";
import {} from "@material-ui/icons";
import { GetApp as GetAppIcon, Book as BookIcon, Link as LinkIcon } from "@material-ui/icons";
import styled from "styled-components";

interface Props {}

export function SelectionSidebarActions(props: Props) {
  return render();

  function render() {
    return (
      <Styles>
        <Tooltip title="Download GIS">
          <a
            href={`${process.env.PUBLIC_URL}/misc/walkabilityscore-tool_bestanden.zip`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton className="icon" size="small">
              <GetAppIcon />
            </IconButton>
          </a>
        </Tooltip>

        <Tooltip title="Handleiding">
          <a
            href="https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/walkability-tool#materialen"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton className="icon" size="small">
              <BookIcon />
            </IconButton>
          </a>
        </Tooltip>

        <Tooltip title="Link website">
          <a
            href="https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/walkability-tool"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton className="icon" size="small">
              <LinkIcon />
            </IconButton>
          </a>
        </Tooltip>
      </Styles>
    );
  }
}

const Styles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(0, 4)};

  .icon {
    width: 40px;
    height: 40px;
    background: ${({ theme }) => `${theme.palette.background.paper}`};

    svg {
      width: 15px;
      fill: black;
    }
  }
`;
