import { divIcon } from "leaflet";
import { Marker } from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import { Room as RoomIcon } from "@material-ui/icons";

import { useMarkers } from "../../hooks";

export const MapMarkers = () => {
  const markers = useMarkers();

  return (
    <>
      {markers.map((value, index) => (
        <Marker
          key={`marker-${index}`}
          position={value.latLng}
          icon={divIcon({
            html: ReactDOMServer.renderToString(
              <RoomIcon className="marker-icon" fontSize="large" style={{ fill: value.markerColor }} />
            ),
          })}
        />
      ))}
    </>
  );
};
