import Axios from "axios";
import { LatLng } from "leaflet";
import { useQuery } from "react-query";
import { MultiLineString, Polygon } from "wkx";

interface WalkingDistanceLink {
  link_id: number;
  segment: number;
}

type FetchWalkingDistanceResponse = MultiLineString | Polygon;

export function useWalkingDistance(latLng: LatLng, distance: number) {
  async function queryFn(latLng: LatLng, distance: number) {
    const config = {
      x: latLng.lng,
      y: latLng.lat,
      distance,
    };
    const response = await Axios.post<{ data: FetchWalkingDistanceResponse }>("/api/walkingdistance", config);
    return response.data.data;
  }
  return useQuery<FetchWalkingDistanceResponse>([`walkingdistance`, latLng, distance], () => queryFn(latLng, distance));
}

export function useWalkingDistanceLink(latLng: LatLng, distance: number) {
  async function queryFn(latLng: LatLng, distance: number): Promise<WalkingDistanceLink> {
    const url = "/api/walkingdistanceLink";
    const config = {
      x: latLng.lng,
      y: latLng.lat,
      distance,
    };
    const response = await Axios.post<{ data: WalkingDistanceLink }>(url, config);
    return response.data.data;
  }
  return useQuery([`walkingdistanceLink`, latLng, distance], () => queryFn(latLng, distance));
}
