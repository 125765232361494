import { Typography } from "@material-ui/core";
import { BaseOverlayer } from "../../types";

interface Props {
  overLayer: BaseOverlayer;
}

export function MapExtraLayerLegendItem(props: Props) {
  function getImageUrl(overlayer: BaseOverlayer) {
    return `${overlayer.wmsLayer.url}?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${overlayer.wmsLayer.layers}&style=${overlayer.wmsLayer.styles}`;
  }

  return (
    <>
      <Typography>{props.overLayer.name}</Typography>
      <img alt="Geen legende gevonden" src={getImageUrl(props.overLayer)} />
    </>
  );
}
