import { atom } from "jotai";
import { ZoneDistance, ZoneDistanceKey } from "../types";
import { walkOptions, bikeOptions, electricBikeOptions } from "./../config/walking-distances";

export const zoneDistanceAtom = atom<{
  [ZoneDistanceKey.walkingDistance]: ZoneDistance;
  [ZoneDistanceKey.bikeDistance]: ZoneDistance;
  [ZoneDistanceKey.electricBikeDistance]: ZoneDistance;
}>({
  walkingDistance: { distance: walkOptions[4].distance, isActive: true },
  bikeDistance: { distance: bikeOptions[0].distance, isActive: false },
  electricBikeDistance: {
    distance: electricBikeOptions[0].distance,
    isActive: false,
  },
});
