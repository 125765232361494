import { atom, Getter, Setter } from "jotai";
import { BaseOverlayer, BelfiusOverlayer, DemographicOverlayer, ExtraInfoOverlayer, SpatialOverlayer } from "../types";
import { spatialOverlayers } from "../config/overlayers/spatial-overlayers";
import { demographicOverlayers } from "../config/overlayers/demographic-overlayers";
import { extraInfoOverlayers } from "../config/overlayers/extra-info-overlayers";
import { belfiusOverlayers } from "../config/belfius-overlayers";

export const belfiusOverlayersAtom = atom<BelfiusOverlayer[]>(belfiusOverlayers);
export const activeBelfiusOverlayerAtom = atom((get) => get(belfiusOverlayersAtom).find((o) => o.isActive));
export const updateActiveBelfiusOverlayerAtom = atom(
  null,
  (get: Getter, set: Setter, update: BelfiusOverlayer["key"]) => {
    set(
      belfiusOverlayersAtom,
      get(belfiusOverlayersAtom).map((overlayer) => {
        if (overlayer.key === update) return { ...overlayer, isActive: true };
        else return { ...overlayer, isActive: false };
      })
    );
  }
);

export const spatialOverlayersAtom = atom<SpatialOverlayer[]>(spatialOverlayers);
export const activeSpatialOverlayerAtom = atom((get) => get(spatialOverlayersAtom).find((o) => o.isActive));
export const updateActiveSpatialOverlayerAtom = atom(
  null,
  (get: Getter, set: Setter, update: BaseOverlayer["key"] | null) => {
    set(
      spatialOverlayersAtom,
      get(spatialOverlayersAtom).map((overlayer) => {
        if (overlayer.key === update) return { ...overlayer, isActive: true };
        else return { ...overlayer, isActive: false };
      })
    );
  }
);

export const demographicOverlayersAtom = atom<DemographicOverlayer[]>(demographicOverlayers);
export const activeDemographicOverlayerAtom = atom((get) => get(demographicOverlayersAtom).find((o) => o.isActive));
export const updateActiveDemographicOverlayerAtom = atom(
  null,
  (get: Getter, set: Setter, update: BaseOverlayer["key"] | null) => {
    set(
      demographicOverlayersAtom,
      get(demographicOverlayersAtom).map((overlayer) => {
        if (overlayer.key === update) return { ...overlayer, isActive: true };
        else return { ...overlayer, isActive: false };
      })
    );
  }
);

export const extraInfoOverlayersAtom = atom<ExtraInfoOverlayer[]>(extraInfoOverlayers);
export const activeExtraInfoOverlayerAtom = atom((get) => get(extraInfoOverlayersAtom).filter((o) => o.isActive));
export const updateExtraInfoOverlayerAtom = atom(null, (get: Getter, set: Setter, update: ExtraInfoOverlayer) => {
  set(
    extraInfoOverlayersAtom,
    get(extraInfoOverlayersAtom).map((overlayer) => {
      if (overlayer.key === update.key) return update;
      else return overlayer;
    })
  );
});
