import { useState } from "react";
import { Fab, Grid, IconButton, Typography } from "@material-ui/core";
import {} from "@material-ui/icons";
import { List as ListIcon, Close as CloseIcon } from "@material-ui/icons";
import { useAtom } from "jotai";
import styled from "styled-components";

import { extraInfoOverlayersAtom } from "../../atoms/overlayers.atom";
import { BoxHeader } from "../BoxHeader";
import { Spacer } from "../Spacer";
import { MapWindowPaper } from "../surface/PaperBackground";
import { MapExtraLayerLegendItem } from "./MapExtraLayerLegendItem";
import { CustomMapControlBase } from "./CustomMapControlBase";

interface Props {}

export function MapExtraLayerLegend(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [extraInfoOverlayers] = useAtom(extraInfoOverlayersAtom);
  const activeOverLayers = extraInfoOverlayers.filter((overlayer) => overlayer.isActive);

  return render();

  function render() {
    // render nothing with 0 activeOverlayers
    if (activeOverLayers.length === 0) return null;

    return (
      <CustomMapControlBase position="topright">
        {isOpen && renderOpen()}
        {!isOpen && renderClosed()}
      </CustomMapControlBase>
    );
  }

  function renderClosed() {
    return (
      <Fab onClick={() => setIsOpen(true)} size="medium" color="secondary">
        <ListIcon fontSize="small" />
      </Fab>
    );
  }

  function renderOpen() {
    return (
      <Styles>
        <>
          <BoxHeader>
            Legende extra kaartlagen
            <Spacer />
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </BoxHeader>
          <Grid container component={MapWindowPaper} square direction="row">
            <div className="legend-container">
              {activeOverLayers.length === 0 && <Typography>Geen actieve extra kaartlagen gevonden.</Typography>}
              {activeOverLayers.length > 0 &&
                activeOverLayers.map((overLayer) => (
                  <MapExtraLayerLegendItem key={overLayer.key} overLayer={overLayer} />
                ))}
            </div>
          </Grid>
        </>
      </Styles>
    );
  }
}

const Styles = styled.div`
  width: fit-content;

  .MuiGrid-container {
    overflow: auto;
    max-height: 60vh;
  }

  .legend-container {
    img {
      max-width: 100%;
    }
  }
`;
