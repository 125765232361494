import React, { useMemo, useEffect, ChangeEvent, useState, useRef } from "react";
import { throttle } from "lodash-es";
import axios from "axios";
import { Fade, Grid, ListItem, Popper, TextField, TextFieldProps, Tooltip, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { LocationOn, Search as SearchIcon } from "@material-ui/icons";
import { setViewPosition } from "../../utils/map.utils";
import { useMap } from "react-leaflet";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { MenuPaper } from "../surface/PaperBackground";

type Props = {
  label: string;
  name: string;
  className: string;
  variant: "standard" | "filled" | "outlined";
  value?: string;
} & TextFieldProps;

export interface LocationResult {
  Municipality: string;
  Zipcode: string;
  Thoroughfarename: string;
  Housenumber: string;
  Location: {
    Lat_WGS84: number;
    Lon_WGS84: number;
    X_Lambert72: number;
    Y_Lambert72: number;
  };
  BoundingBox: {
    LowerLeft: {
      Lat_WGS84: number;
      Lon_WGS84: number;
      X_Lambert72: number;
      Y_Lambert72: number;
    };
    UpperRight: {
      Lat_WGS84: number;
      Lon_WGS84: number;
      X_Lambert72: number;
      Y_Lambert72: number;
    };
  };
}

export const ListItemAddressSearch = (props: Props) => {
  const { label, name, className, value: boundValue, error, helperText } = props;

  const anchor = useRef(null);
  const { activeAction } = useParams<{ activeAction: string }>();
  const history = useHistory();
  const map = useMap();

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<LocationResult | null>(null);
  const [options, setOptions] = useState<any[]>(!value && inputValue ? [inputValue] : []);

  const fetchSuggestions = useMemo(
    () =>
      throttle(async (request: { input: string }, callback: (results: any[]) => void) => {
        if (request && request.input) {
          const result = await axios.get(`https://geo.api.vlaanderen.be/geolocation/v4/Suggestion?q=${request.input.toLowerCase()}`);

          if (result.status === 200 && result.data && result.data.SuggestionResult)
            return callback(result.data.SuggestionResult);
        }
        return callback([]);
      }, 375),
    []
  );

  useEffect(() => {
    if (boundValue) {
      setInputValue(boundValue);
    }
  }, [boundValue]);

  const mapSelectedToLocation = async (unvalidatedResult: string) => {
    await fetchLocations(unvalidatedResult, (validatedResult: LocationResult | null) => {
      setValue(validatedResult);
      if (validatedResult && mapLocationResultToAddress(validatedResult) !== inputValue) {
        onChangeLocation(validatedResult);
        setInputValue(mapLocationResultToAddress(validatedResult));
      }
    });
  };

  const mapSelectedToLocationCallback = useRef(mapSelectedToLocation).current;

  useEffect(() => {
    let active = true;
    fetchSuggestions({ input: inputValue }, (results: string[]) => {
      if (active) {
        setOptions([...results]);
        if (results && results.length === 1 && results[0] === inputValue) {
          mapSelectedToLocationCallback(inputValue);
          setInputValue(inputValue);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchSuggestions, mapSelectedToLocationCallback]);

  function getRoute(route: string) {
    if (history.location.pathname === route) {
      return "/";
    }
    return route;
  }

  function onChangeLocation(location: LocationResult) {
    setViewPosition(
      {
        lat: location.Location.Lat_WGS84,
        lng: location.Location.Lon_WGS84,
        zoom: 14,
        type: "search",
        boundingBox: location.BoundingBox,
      },
      map
    );
  }

  return (
    <>
      <Tooltip title="Zoek">
        <ListItem
          className="list-item"
          selected={activeAction === "search"}
          button
          component={NavLink}
          ref={anchor}
          to={{ ...history.location, pathname: getRoute(`/search`) }}
        >
          <SearchIcon />
        </ListItem>
      </Tooltip>
      <Popper open={activeAction === "search"} anchorEl={anchor.current} disablePortal={false} placement="left-start">
        <Fade in={activeAction === "search"}>
          <MenuPaper>
            <Autocomplete
              fullWidth
              getOptionLabel={(option: string) =>
                typeof option === "string" ? option : mapLocationResultToAddress(option)
              }
              className={className}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={inputValue}
              onChange={(event: ChangeEvent<{}>, newValue: string | null) => {
                if (newValue) {
                  setOptions(newValue ? [newValue, ...options] : options);
                  mapSelectedToLocation(newValue);
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue.toLowerCase());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  name={name}
                  variant="standard"
                  fullWidth
                  error={error}
                  helperText={helperText}
                  className={"address-search"}
                />
              )}
              renderOption={(option) => {
                const parts = option.split(",");
                const commune: string = parts.pop() || "";
                const street: string = parts.join(",");

                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOn className="icon" />
                    </Grid>
                    <Grid item xs>
                      {street}
                      <Typography variant="body2" color="textSecondary">
                        {commune}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </MenuPaper>
        </Fade>
      </Popper>
    </>
  );
};

async function fetchLocations(input: string, callback: (result: LocationResult | null) => void) {
  const result = await axios.get(`https://geo.api.vlaanderen.be/geolocation/v4/Location?q=${input.toLowerCase()}`);

  if (result.status === 200) return callback(result.data.LocationResult.shift());
  return callback(null);
}

function mapLocationResultToAddress(result: LocationResult | null): string {
  const parts: string[] = [];
  if (!result) {
    return "";
  }
  if (result.Thoroughfarename) {
    if (result.Housenumber) {
      parts.push(result.Thoroughfarename, `${result.Housenumber},`);
    } else {
      parts.push(`${result.Thoroughfarename},`);
    }
  } else if (result.Housenumber) {
    parts.push(result.Housenumber);
  }
  if (result.Zipcode) {
    parts.push(result.Zipcode);
  }
  if (result.Municipality) {
    parts.push(result.Municipality);
  }
  return parts.join(" ");
}
