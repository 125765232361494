import axios from "axios";
import { FeatureCollection } from "geojson";
import BelfiusJson from "../assets/json/belfius-clusters.json";
import { quantilesJsonFiles } from "../assets/json/quantiles";
import { MyMapClick, BaseOverlayer } from "../types";
import { RasterQuantiles } from "guppy.ts";
import { getQuantileLabel } from "./quantile.utils";

export interface Cluster {
  cluster_code: string;
  cluster_naam: string;
}

export interface BelfiusClusterInfo {
  clusterName: string;
  rangeLabel: string;
}

const getClusterForNisCode = (nisCode: string): Cluster | undefined => {
  const foundElement = BelfiusJson.clusterdata.find((element) => {
    return element.niscode === nisCode;
  });
  return foundElement ? getClusterForCode(foundElement.cluster_code) : undefined;
};

const getClusterForCode = (clusterCode: string): Cluster | undefined => {
  const filteredCluster = BelfiusJson.uniqueclusters.find((el) => {
    return el.cluster_code === clusterCode;
  });
  return filteredCluster;
};

export const getPlaceName = async (mapClick: MyMapClick | undefined): Promise<string | undefined> => {
  if (!mapClick) {
    return undefined;
  }
  const nisCodeData = await getNisCodeData(mapClick);
  if (!nisCodeData || !nisCodeData.features || !nisCodeData.features[0]) {
    return undefined;
  }
  return nisCodeData.features[0].properties?.label;
};

export async function getCluster(mapClick: MyMapClick) {
  const nisCodeData = await getNisCodeData(mapClick);
  if (!nisCodeData.features || !nisCodeData.features[0]) {
    return;
  }
  const cluster = getClusterForNisCode(nisCodeData.features[0].properties?.NISCODE);
  return cluster;
}

//  export async function getBelfiusQuantiles(mapClick: MyMapClick, score: number, overlayer: Overlayer) {
//   // const nisCodeData = await getNisCodeData(mapClick);
//   // if (!nisCodeData.features || !nisCodeData.features[0]) {
//   //   return;
//   // }
//   // const cluster = getClusterForNisCode(nisCodeData.features[0].properties?.NISCODE);
//   // if (!cluster) {
//   //   return;
//   // }
//   const cluster = await getCluster(mapClick);
//   if (!cluster) return null;

//   const quantiles = loadQuantiles(overlayer.wmsLayer.layers, cluster.cluster_code);
//   return quantiles;
// }

export const getBelfiusClusterInfo = async (
  mapClick: MyMapClick,
  score: number,
  overlayer: BaseOverlayer | null
): Promise<BelfiusClusterInfo | null> => {
  try {
    if (!overlayer) return null;
    const cluster = await getCluster(mapClick);
    if (!cluster) return null;

    const activeBelfiusRanges = loadQuantiles(overlayer.wmsLayer.layers, cluster.cluster_code);
    if (!activeBelfiusRanges) return null;

    return {
      clusterName: cluster.cluster_naam,
      rangeLabel: getQuantileLabel(activeBelfiusRanges, score),
    };
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
};

export const getNisCodeData = async (mapClick: MyMapClick): Promise<FeatureCollection> => {
  const config = {
    params: {
      request: "GetFeatureInfo",
      service: "WMS",
      srs: "EPSG:4326",
      version: "1.1.1",
      bbox: mapClick.bbox.toBBoxString(),
      height: mapClick.mapSize.y,
      width: mapClick.mapSize.x,
      layers: "walkability:gemeenten",
      query_layers: "walkability:gemeenten",
      info_format: "application/json",
      x: Math.round(mapClick.x),
      y: Math.round(mapClick.y),
    },
  };
  try {
    const { data } = await axios.get(`/geoserver/wms`, config);
    return data;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
};

export function loadQuantiles(layerName: string, clusterCode: string): RasterQuantiles[] {
  const quantilesFile = quantilesJsonFiles[layerName][clusterCode];
  const [p0, p25, p50, p75, p100] = quantilesFile;

  return [
    {
      quantile: 0,
      value: p0,
    },
    {
      quantile: 0.25,
      value: p25,
    },
    {
      quantile: 0.5,
      value: p50,
    },
    {
      quantile: 0.75,
      value: p75,
    },
    {
      quantile: 1,
      value: p100,
    },
  ];
}
