import { Typography } from "@material-ui/core";
import { Component } from "react";

interface Props {}
interface State {
  hasError: boolean;
}

export class OutOfBoundsErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Typography variant="body1">Gelieve een locatie op de kaart te kiezen.</Typography>;
    }

    return this.props.children;
  }
}
