import { useAtom } from "jotai";
import { TileLayer } from "react-leaflet";
import { activeBaselayerAtom } from "../../atoms/baselayers.atom";

export function BaselayerOnMap() {
  const [activeBaseLayer] = useAtom(activeBaselayerAtom);

  // render the component
  return render();

  function render() {
    if (!activeBaseLayer) return null;
    else
      return (
        <TileLayer key={activeBaseLayer.key} url={activeBaseLayer.url} attribution={activeBaseLayer.attribution} />
      );
  }
}
