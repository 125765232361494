import { LeafletMouseEvent } from "leaflet";
import { useMap, useMapEvent } from "react-leaflet";
import { useHistory, useParams } from "react-router";
import { useMarkers } from "../../hooks";
import { MyMapClick } from "../../types";
import { getMapClick } from "../../utils/map.utils";

export const MapHandleClick = () => {
  const mapRef = useMap();
  const markers = useMarkers();
  const { activeAction } = useParams<{ activeAction: string }>();
  const history = useHistory();

  function onClick(event: LeafletMouseEvent) {
    const mapClick = getMapClick(event, mapRef);
    const markerArray = getMarkerArray(activeAction, markers, mapClick);
    history.push({
      search: `${markerArray.map((marker) => `markers=${marker.latLng.lat},${marker.latLng.lng}&`).join("")}`,
    });
  }

  useMapEvent("click", onClick);

  return null;
};

// only allow multiple markers when on /analyse route
function getMarkerArray(action: string, markers: MyMapClick[], mapClick: MyMapClick) {
  if (action === "analyse") {
    return [...markers, mapClick];
  }
  return [mapClick];
}
