import { FormControlLabel, IconButton, Radio, RadioGroup } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import { useAtom } from "jotai";
import { useMemo } from "react";
import styled from "styled-components";

import {
  activeDemographicOverlayerAtom,
  demographicOverlayersAtom,
  updateActiveDemographicOverlayerAtom,
  updateActiveSpatialOverlayerAtom,
} from "../atoms/overlayers.atom";
import { DemographicOverlayer } from "../types";
import { BoxHeader } from "./BoxHeader";
import { IndicatorWithCategory } from "./IndicatorWithCategory";

interface Props {
  onClickMoreInformation: () => void;
}

export const DemographicIndicatorSelection = (props: Props) => {
  const [indicators] = useAtom(demographicOverlayersAtom);
  const [activeDemographicOverlayer] = useAtom(activeDemographicOverlayerAtom);
  const [, setActiveSpatialOverlayer] = useAtom(updateActiveSpatialOverlayerAtom);
  const [, setActiveDemographicOverlayer] = useAtom(updateActiveDemographicOverlayerAtom);

  const categories = useMemo(() => {
    return indicators.reduce((categoryAcc: string[], indicator) => {
      if (!indicator.category || categoryAcc.includes(indicator.category)) return categoryAcc;
      else return [...categoryAcc, indicator.category];
    }, []);
  }, [indicators]);

  function updateActiveDemographicOverlayer(key: DemographicOverlayer["key"]) {
    setActiveDemographicOverlayer(key);
    setActiveSpatialOverlayer(key);
  }

  return render();

  function render() {
    return (
      <Styles>
        <RadioGroup value={activeDemographicOverlayer?.key || ""}>
          <BoxHeader>
            Demografische gegevens
            <IconButton onClick={props.onClickMoreInformation}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </BoxHeader>
          <div className="list">
            {/* indicators WITHOUT category */}
            {indicators
              .filter((indicator) => !indicator.category)
              .map((indicator) => (
                <FormControlLabel
                  key={indicator.key}
                  value={indicator.key}
                  control={<Radio color="primary" onClick={() => updateActiveDemographicOverlayer(indicator.key)} />}
                  label={indicator.name}
                />
              ))}

            {/* indicators WITH category */}
            {categories.map((category) => (
              <IndicatorWithCategory
                key={category}
                category={category}
                activeIndicator={activeDemographicOverlayer}
                indicators={indicators.filter((indicator) => indicator.category === category)}
                onChange={updateActiveDemographicOverlayer}
              />
            ))}
          </div>
        </RadioGroup>
      </Styles>
    );
  }
};

const Styles = styled.div`
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    padding-left: ${({ theme }) => theme.spacing(2)}px;
  }

  .list {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
    padding-right: ${({ theme }) => theme.spacing(1)}px;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;
