import { useHistory } from "react-router";
import styled from "styled-components";
import { Room as RoomIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useAtom } from "jotai";

import { useMarkers } from "../../hooks";
import { useRasterData } from "../../hooks/useRasterData";
import { MyMapClick } from "../../types";
import { OutOfBoundsErrorBoundary } from "../OutOfBoundsErrorBoundary";
import { activeSpatialOverlayerAtom } from "../../atoms/overlayers.atom";
import { Spacer } from "../Spacer";

interface Props {
  marker: MyMapClick;
  index: number;
}

export const MarkerDetails = (props: Props) => {
  const { marker, index } = props;

  const markers = useMarkers();
  const history = useHistory();

  function deleteMarker(index: number) {
    const newMarkers = [...markers];
    newMarkers.splice(index, 1);
    history.push({
      search: `${newMarkers.map((marker) => `markers=${marker.latLng.lat},${marker.latLng.lng}&`).join("")}`,
    });
  }

  return (
    <MarkerRow key={index}>
      <RoomIcon className="marker-icon" style={{ fill: marker.markerColor }} fontSize="medium" />

      <OutOfBoundsErrorBoundary>
        <MarkerInfo marker={marker} />
      </OutOfBoundsErrorBoundary>

      <Spacer />
      <IconButton
        size="small"
        onClick={() => {
          deleteMarker(index);
        }}
      >
        <DeleteIcon fontSize="small" style={{ color: "#D1D1D1" }} />
      </IconButton>
    </MarkerRow>
  );
};

interface MarkerInfoProps {
  marker: MyMapClick;
}

const MarkerInfo = (props: MarkerInfoProps) => {
  const { marker } = props;

  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);
  // const activeSpatialOverlayer = useStore((state) => state.overlayer);
  const { data: score } = useRasterData(activeSpatialOverlayer?.wmsLayer.layers || null, marker.latLng);

  if (!score || !activeSpatialOverlayer) {
    return null;
  }

  return (
    <div className="marker-data">
      <div className="label">{activeSpatialOverlayer.name}</div>
      {"-"}
      <div className="score"> {score}</div>
    </div>
  );
};

const MarkerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(1)}px;

  .marker-icon {
    transform: translateY(-1px);
  }

  .marker-data {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.spacing(1)}px;

    .label {
      width: fit-content;
      flex: 0 0 auto;
      font-size: 0.8rem;
    }
    .score {
      flex-shrink: 0;
      font-weight: bold;
    }
  }
`;
