import { MutableRefObject, Suspense } from "react";
import { Button, Drawer, Portal, Toolbar, Typography, Tooltip } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router";

import { Spinner } from "../Spinner";
import { PanePaper } from "../surface/PaperBackground";
// import { useStore, ZoneDistance, ZoneDistanceKey } from '../../store/store';
import { bikeOptions, electricBikeOptions, walkOptions } from "../../config/walking-distances";
import styled from "styled-components";
import { ZoneDistanceSelect } from "../ZoneDistanceSelect";
import { zoneDistanceAtom } from "../../atoms/zoneDistance.atom";
import { HistoryState, ZoneDistance, ZoneDistanceKey } from "../../types";
import { useAtom } from "jotai";
import { useMarkers } from "../../hooks";

interface Props {
  paneContainer: MutableRefObject<HTMLDivElement | null>;
}

export const ZoneDistancePane = (props: Props) => {
  const { paneContainer } = props;
  const history = useHistory<HistoryState>();
  const location = useLocation();

  const { activeAction } = useParams<{ activeAction: string }>();
  const [marker] = useMarkers();

  const [zoneDistances, setZoneDistances] = useAtom(zoneDistanceAtom);
  function updateZoneDistance(key: ZoneDistanceKey, partialDistance: Partial<ZoneDistance>) {
    setZoneDistances({
      ...zoneDistances,
      [key]: { ...zoneDistances[key], ...partialDistance },
    });
  }

  function handleClickDownloadAll() {
    const downloadConfig: HistoryState = {
      download: {
        visualName: "Downloaden van GIS bestanden voor de geselecteerde locatie",
        filename: "walkabilityscore-tool_wandel_en_fietsbereik.zip",
        config: {
          method: "post",
          url: "/api/download",
          responseType: "arraybuffer",
          data: {
            x: marker.latLng.lng,
            y: marker.latLng.lat,
            distanceList: [600, 800, 1000, 1200, 1750, 3750, 5800],
          },
        },
      },
    };

    history.push({
      ...location,
      state: downloadConfig,
    });
  }

  return render();

  function render() {
    if (activeAction !== "zone") return null;

    return (
      <Portal container={paneContainer.current}>
        <StyledDrawer variant="persistent" open={true} anchor="right" PaperProps={{ component: PanePaper }}>
          <Toolbar />
          <Suspense fallback={<Spinner />}>
            <div className="body">
              <Typography variant="h3">Wandel- en fietsbereik</Typography>

              <Typography variant="body1">
                Via deze functie kan je het wandel- en fietsbereik per doelgroep weergeven. Zo zie je wat het gemiddeld
                bereik is bij 15 minuten wandelen of fietsen. De weergave van het bereik is op basis van het
                stratennetwerk. Deze weergave houdt geen rekening met aspecten zoals de inrichting van straten, het
                wegstatuut en specifieke noden vanuit een doelgroep.
              </Typography>

              <Typography variant="body1">
                Klik op een punt op de kaart om het bereik van een doelgroep weer te geven. Hieronder kan je het wandel-
                en fietsbereik per doelgroep aanvinken.
              </Typography>

              <ZoneDistanceSelect
                title="Wandelafstand"
                value={zoneDistances.walkingDistance.distance}
                zoneDistance={zoneDistances.walkingDistance}
                options={walkOptions}
                onToggleActive={() =>
                  updateZoneDistance(ZoneDistanceKey.walkingDistance, {
                    isActive: !zoneDistances.walkingDistance.isActive,
                  })
                }
                onChangeDistance={(distance: number) =>
                  updateZoneDistance(ZoneDistanceKey.walkingDistance, {
                    distance,
                  })
                }
              />

              <ZoneDistanceSelect
                title="Fietsafstand"
                value={zoneDistances.bikeDistance.distance}
                zoneDistance={zoneDistances.bikeDistance}
                options={bikeOptions}
                onToggleActive={() =>
                  updateZoneDistance(ZoneDistanceKey.bikeDistance, {
                    isActive: !zoneDistances.bikeDistance.isActive,
                  })
                }
                onChangeDistance={(distance: number) => updateZoneDistance(ZoneDistanceKey.bikeDistance, { distance })}
              />

              <ZoneDistanceSelect
                title="Elektrische fietsafstand"
                value={zoneDistances.electricBikeDistance.distance}
                zoneDistance={zoneDistances.electricBikeDistance}
                options={electricBikeOptions}
                onToggleActive={() =>
                  updateZoneDistance(ZoneDistanceKey.electricBikeDistance, {
                    isActive: !zoneDistances.electricBikeDistance.isActive,
                  })
                }
                onChangeDistance={(distance: number) =>
                  updateZoneDistance(ZoneDistanceKey.electricBikeDistance, {
                    distance,
                  })
                }
              />

              <Tooltip title={!marker ? "Voeg eerst een punt op de kaart toe" : ""}>
                <span>
                  <Button disabled={!marker} onClick={handleClickDownloadAll} fullWidth>
                    Download GIS bestanden
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Suspense>
        </StyledDrawer>
      </Portal>
    );
  }
};

const StyledDrawer = styled(Drawer)`
  width: 330px;
  overflow: visible;

  .body {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)}px;
  }
`;
