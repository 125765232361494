import React, { MutableRefObject } from "react";
import styled from "styled-components";
import { Header } from "../components/Header";
import { SelectionSidebar } from "../components/SelectionSidebar";
import { Toolbar } from "@material-ui/core";

interface Props {
  children: React.ReactNode;
  className?: string;
  actionBarRef: MutableRefObject<HTMLDivElement | null>;
  paneRef: MutableRefObject<HTMLDivElement | null>;
}

export const AppLayout = (props: Props) => {
  const { className, children, actionBarRef, paneRef: analysePaneRef } = props;

  return (
    <Styles className={className}>
      <Header />
      <Toolbar />
      <div className="main-content">
        <SelectionSidebar className="selection-sidebar" />
        <section>{children}</section>
        <div ref={analysePaneRef}></div>
        <div ref={actionBarRef}></div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .content {
    flex-grow: 1;
    position: relative;
    display: flex;
    overflow-x: hidden;
  }

  .main-content {
    display: flex;
    height: calc(100vh - 64px);
  }

  .selection-sidebar {
    overflow-y: auto;
    flex: 0 0 330px;
    width: 330px;
  }

  section {
    flex: 1;
  }
`;
