import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import * as serviceWorker from "./serviceWorker";
import { RootPage } from "./pages/RootPage";

import { GlobalStyle } from "./styles/GlobalStyles";
import { queryClient } from "./query-client";
import { PageLoader } from "./components/PageLoader";
import { theme } from "./config";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId:
    process.env.NODE_ENV !== "development" ? process.env.REACT_APP_GTM_ID || "GTM-unconfigured" : "GTM-unconfigured",
};
TagManager.initialize(tagManagerArgs);

const MOUNT_NODE = document.getElementById("root");
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <ConfirmProvider>
          <Router>
            <Suspense fallback={<PageLoader />}>
              <Route path="/" component={RootPage} />
            </Suspense>
          </Router>
        </ConfirmProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </QueryClientProvider>,
  MOUNT_NODE
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
