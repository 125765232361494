import { RasterQuantiles } from "guppy.ts";
import { LatLng } from "leaflet";
import styled from "styled-components";
import { useRasterData } from "../hooks";
import { BaseOverlayer } from "../types";
import { positionOnLegend } from "../utils/legend.utils";

interface Props {
  latLng: LatLng;
  layer: BaseOverlayer;
  quantiles: RasterQuantiles[];
  color: string;
  gradientWidth: number;
}

export const MarkerOnGradient = (props: Props) => {
  const { latLng, layer, color } = props;

  const { data: score } = useRasterData(layer.wmsLayer.layers, latLng);

  return render();

  function render() {
    if (!score) return null;

    return <Styles leftDistance={positionOnLegend(props.quantiles, score, props.gradientWidth)} color={color}></Styles>;
  }
};

const Styles = styled.div<{
  leftDistance: number;
  color?: string;
}>`
  position: absolute;
  left: ${({ leftDistance }) => `${leftDistance}px`};
  margin-top: 10px;
  width: 1px;
  height: 22px;
  background: ${({ color }) => (color ? color : "black")};

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${({ color }) => (color ? color : "black")};
    bottom: -8px;
    transform: translateX(-50%);
    margin-left: 50%;
  }
`;
