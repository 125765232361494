import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import vlOmgeving from "../assets/images/vlaanderen_omgeving@2x.png";
import vlZorg from "../assets/images/vlaanderen_zorg@2x.png";

export const ImagesFooter = () => {
  return (
    <StyledGrid container justifyContent="space-between">
      <Grid container item xs={4}>
        <img alt="vlzorg" src={vlZorg} />
      </Grid>
      <Grid container item xs={4}>
        <img alt="vlomgeving" src={vlOmgeving} />
      </Grid>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  height: 43px;

  img {
    height: 43px;
  }

  .MuiGrid-item {
    max-width: 100%;
  }
`;
