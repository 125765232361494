import useMeasure from "react-use-measure";
import { ColorMap } from "marvin-ui-kit";
import styled from "styled-components";

import { MyMapClick, SpatialOverlayer } from "../types";
import { colorMapFromQuantiles } from "../utils/legend.utils";
import { MarkerOnGradient } from "./MarkerOnGradient";
import { RasterQuantiles } from "guppy.ts";

export function PunaiseChart(props: { quantiles: RasterQuantiles[]; markers: MyMapClick[]; layer: SpatialOverlayer }) {
  const [containerRef, containerBounds] = useMeasure();

  return render();

  function render() {
    const colorMap = colorMapFromQuantiles(props.quantiles);

    return (
      <Styles colorMap={colorMap} ref={containerRef}>
        <div className="labels">
          {props.quantiles.map((quantile) => (
            <span key={quantile.quantile}>{format(quantile.value)}</span>
          ))}
        </div>

        <div className="gradient-container">
          {props.markers.map((marker, index) => (
            <MarkerOnGradient
              key={index}
              latLng={marker.latLng}
              layer={props.layer}
              quantiles={props.quantiles}
              color={marker.markerColor || ""}
              gradientWidth={containerBounds.width}
            />
          ))}
        </div>
      </Styles>
    );
  }
}

function format(value: number): string {
  return value.toFixed(2);
}

const Styles = styled.div<{ colorMap: ColorMap }>`
  .labels {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }

  .gradient-container {
    position: relative;
    display: block;
    background: linear-gradient(
      to left,
      ${({ colorMap }) => colorMap.entries.map((colorEntry) => colorEntry.color).join()}
    );

    width: 100%;
    height: 10px;
    border-radius: 8px;
  }
`;
