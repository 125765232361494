import { FormControlLabel, MenuItem, Radio, Select } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";

import { DemographicOverlayer } from "../types";

interface Props {
  category: string;
  activeIndicator: DemographicOverlayer | undefined;
  indicators: DemographicOverlayer[];
  onChange: (indicator: DemographicOverlayer["key"]) => void;
}

export function IndicatorWithCategory(props: Props) {
  const [activeCategoryIndicator, setActiveCategoryIndicator] = useState(props.indicators[0]);

  function handleCategoryIndicatorChange(event: ChangeEvent<{ value: unknown }>) {
    const result = props.indicators.find((indicator) => indicator.key === event.target.value);
    if (result) {
      setActiveCategoryIndicator(result);

      // if the current activeIndicator is withing this category, active new selection
      if (props.activeIndicator?.category === props.category) {
        props.onChange(result.key);
      }
    } else console.warn(`Unknown CategoryIndicator ${event.target.value}`);
  }
  return render();

  function render() {
    if (!activeCategoryIndicator) return null;

    return (
      <Styles>
        <FormControlLabel
          value={activeCategoryIndicator.key}
          control={<Radio color="primary" onClick={() => props.onChange(activeCategoryIndicator.key)} />}
          label={
            <>
              <span className="category">{props.category}</span>
              <Select value={activeCategoryIndicator.key} fullWidth onChange={handleCategoryIndicatorChange}>
                {props.indicators.map((indicator) => (
                  <MenuItem key={indicator.key} value={indicator.key}>
                    {indicator.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          }
        />
      </Styles>
    );
  }
}

const Styles = styled.div`
  .category {
    text-transform: capitalize;
  }
  .MuiFormControlLabel-label {
    display: flex;
    /* justify-content: space-between; */
    gap: ${({ theme }) => theme.spacing(1)}px;
    align-items: center;
    width: 100%;
  }
`;
