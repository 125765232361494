import { WalkingDistance } from "../types";

export const walkOptions: WalkingDistance[] = [
  {
    label: "Ouderen minder mobiel: 0,6 km",
    distance: 600,
  },
  {
    label: "Ouderen 65+: 0,8 km",
    distance: 800,
  },
  {
    label: "Volwassenen: 1,2 km",
    distance: 1200,
  },
  {
    label: "Kinderen secundaire school: 1 km",
    distance: 1001,
  },
  {
    label: "Kinderen lagere school: 1 km",
    distance: 1000,
  },
];

export const bikeOptions: WalkingDistance[] = [
  {
    label: "Ouderen 65+: 3,75 km",
    distance: 3751,
  },
  {
    label: "Volwassenen: 3,75 km",
    distance: 3750,
  },
  {
    label: "Kinderen secundaire school: 3,75 km",
    distance: 3749,
  },
  {
    label: "Kinderen lagere school: 1,75 km",
    distance: 1750,
  },
];

export const electricBikeOptions: WalkingDistance[] = [
  {
    label: "Ouderen minder mobiel: 3,75 km",
    distance: 3752,
  },
  {
    label: "Volwassenen: 5,8 km",
    distance: 5800,
  },
];
