import quantiles_walkability from "./quantiles_walkability.json";
import quantiles_walkability_bebouwd from "./quantiles_walkability_bebouwd.json";
import quantiles_woondichtheid_zscore from "./quantiles_woondichtheid_zscore.json";
import quantiles_functiediversiteit_zscore from "./quantiles_functiediversiteit_zscore.json";
import quantiles_stratenconnectiviteit_zscore from "./quantiles_stratenconnectiviteit_zscore.json";

interface quantilesJson {
  [key: string]: number[];
}

interface ImportedQuantilesJsonCollection {
  [key: string]: quantilesJson;
}

export const quantilesJsonFiles: ImportedQuantilesJsonCollection = {
  "walkability_WLK-walkability_v1": quantiles_walkability,
  "walkability_WLK-walkability-woongebied_v1": quantiles_walkability_bebouwd,
  "walkability_z-woondichtheid-VlBr_v1": quantiles_woondichtheid_zscore,
  "walkability_z-functiemix-VlBr_v1": quantiles_functiediversiteit_zscore,
  "walkability_z-stratenconnectiviteit-VlBr_v1": quantiles_stratenconnectiviteit_zscore,
};
