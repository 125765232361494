import * as turf from "@turf/helpers";
import wkx from "wkx";
import { LatLngBounds } from "leaflet";

export function latLngBoundsToGeometry(bounds: LatLngBounds | null): turf.Feature | null {
  if (!bounds) {
    return null;
  }
  const south = bounds.getSouth();
  const west = bounds.getWest();
  const north = bounds.getNorth();
  const east = bounds.getEast();
  // const [[south, west], [north, east]] = bounds;
  if (south > north) return null;

  return turf.polygon([
    [
      [west, north],
      [east, north],
      [east, south],
      [west, south],
      [west, north],
    ],
  ]);
}

export function latLngBoundsToWkt(bounds: LatLngBounds | null): string | undefined {
  return geometryToWtk(latLngBoundsToGeometry(bounds));
}

export function geometryToWtk(boundsGeometry: turf.Feature | null): string | undefined {
  if (!boundsGeometry) {
    return undefined;
  }
  return wkx.Geometry.parseGeoJSON(boundsGeometry.geometry).toWkt();
}

// export function invertGeometry(inputFeature: GeoJsonObject) {
//   const outerFeature = turf.polygon([
//     [
//       [-162.2460938, 73.0738435],
//       [-163.4765625, -75.1857893],
//       [214.453125, -75.0956327],
//       [209.7070313, 71.7464317],
//       [-162.2460938, 73.0738435],
//     ],
//   ]);

//   const result = difference(outerFeature, inputFeature as MultiPolygon);
//   return result;
// }
