import { MapPosition, MyMapClick } from "../types";
import { LatLngBounds, LeafletMouseEvent, LatLng, Map } from "leaflet";

const markerColors = ["#FF0000", "#0000FF", "#008000", "#FFA500", "#EE82EE"];

export const setViewPosition = (coords: MapPosition, mapRef: Map) => {
  if (coords.type === "search") {
    if (coords.boundingBox) {
      mapRef.fitBounds([
        [coords.boundingBox.LowerLeft.Lat_WGS84, coords.boundingBox.LowerLeft.Lon_WGS84],
        [coords.boundingBox.UpperRight.Lat_WGS84, coords.boundingBox.UpperRight.Lon_WGS84],
      ]);
    } else {
      mapRef.setView(coords, mapRef.getZoom());
    }
  }

  return null;
};

export const getMapClick = (event: LeafletMouseEvent, mapRef: Map) => {
  return {
    x: event.containerPoint.x,
    y: event.containerPoint.y,
    mapSize: mapRef.getSize(),
    bbox: mapRef.getBounds(),
    latLng: event.latlng,
  };
};

export const getWKTOfBounds = (bounds: LatLngBounds) => {
  const neLat = bounds.getNorthEast().lat;
  const neLng = bounds.getNorthEast().lng;
  const swLat = bounds.getSouthWest().lat;
  const swLng = bounds.getSouthWest().lng;
  return `POLYGON((${swLng} ${swLat},${swLng} ${neLat},${neLng} ${neLat},${neLng} ${swLat},${swLng} ${swLat} ))`;
};

export const formatMarkerArray = (markers: string[]) => {
  const arr = markers.map((markerAsString) => {
    const latLngSplitted = markerAsString.split(",");
    return new LatLng(Number(latLngSplitted[0]), Number(latLngSplitted[1]));
  });
  return arr;
};

export const getMarkersArray = (latLngArray: LatLng[], map: Map) => {
  const bbox = map.getBounds();
  const mapSize = map.getSize();
  const markersArray = latLngArray.map((latLng, index): MyMapClick => {
    const color = getColor(index);
    const containerPoint = map.latLngToContainerPoint(latLng);
    return {
      x: containerPoint.x,
      y: containerPoint.y,
      bbox,
      mapSize,
      latLng,
      markerColor: color,
    };
  });
  return markersArray;
};

const getColor = (index: number) => {
  if (index === markerColors.length) {
    const randomColor = "#" + ("000000" + Math.floor(Math.random() * 16777216).toString(16)).substr(-6);
    markerColors.push(randomColor);
    return randomColor;
  }
  return markerColors[index];
};
