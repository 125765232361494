import { DialogContent, Typography } from "@material-ui/core";
import { Accordion, AccordionSummary, AccordionDetails, InformationDialogTitle } from "../InformationDialog";

export function DemographicInformation() {
  return (
    <>
      <InformationDialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Meer info over de demografische gegevens
      </InformationDialogTitle>
      <DialogContent>
        <Typography paragraph>
          Aan de hand van deze kaarten kan je de sociaaleconomische status raadplegen van een buurt en zien welke buurt
          kwetsbaar is op het vlak van inkomen of leeftijd.{" "}
          <strong>
            Daar kan je bij het maken van keuzes rekening mee houden en zo meewerken om de steeds grotere{" "}
            <a
              href="https://www.gezondleven.be/gezondheidsongelijkheid/wat-is-gezondheidsongelijkheid?msclkid=9094fc48b44c11ec9ee46be68ecc19dd"
              target="_blank"
              rel="noopener noreferrer"
            >
              gezondheidsongelijkheid
            </a>{" "}
            tegen te gaan.
          </strong>
        </Typography>
        <Accordion>
          <AccordionSummary>
            <Typography>Mediaan inkomen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography variant="body1" paragraph>
                Op deze kaart wordt de mediaan van het bruto gemiddeld jaarinkomen in euro per statistische sector
                weergegeven. Zo zie je in welke statische sectoren er heel veel lage of juist hoge inkomens zijn, en
                over welke buurten het gaat.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>
                  Vanuit gezondheidsperspectief raden we aan om prioritair in te zetten op buurten waar mensen met een
                  lager inkomen wonen, omdat de mogelijke gezondheidswinst bij hen het grootst is.{" "}
                </strong>
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Inwoners &gt; 65 jaar en &lt; 17 jaar</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography variant="body1" paragraph>
                Buurten waar veel ouderen en/of kinderen en jongeren wonen hebben extra aandacht nodig. Zij zijn nóg
                meer afhankelijk van de eigen buurt om zich te voet of per fiets te kunnen verplaatsen. Daarbij blijft
                een integrale, ruimtelijke aanpak belangrijk! Blijf aandacht hebben voor een evenwichtige
                sociodemografische mix in de buurten en voor de noden van álle leeftijden.
              </Typography>

              <Typography variant="body1" paragraph>
                <strong>
                  Door de walkabilityscore te combineren met het aandeel ouderen, kinderen en jongeren in de buurt, kan
                  je voor deze kwetsbare leeftijdsgroepen prioritaire buurten op een objectieve manier aanduiden.
                </strong>
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </>
  );
}
