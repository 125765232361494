import { DialogContent, Typography } from "@material-ui/core";
import { Refresh as RefreshIcon } from "@material-ui/icons";
import { Accordion, AccordionSummary, AccordionDetails, InformationDialogTitle } from "../InformationDialog";

export function WalkabilityLegendInformation() {
  return (
    <>
      <InformationDialogTitle>Meer info over de legende en herschaalfunctie</InformationDialogTitle>
      <DialogContent>
        <div>
          <Accordion>
            <AccordionSummary>
              <Typography>Legende</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph>
                De kleurenbalk in dit venster toont je de kleuren en indeling die gebruikt worden om de scores te
                visualiseren van de kaart die je activeerde. Standaard staat de kaart Walkabilityscore bebouwd gebied
                aan en wordt de daarbij horende kleurenbalk getoond voor Vlaanderen en het Brussels Hoofdstedelijk
                gewest (BHG). Als je een andere kaart activeert, bv. Stratenconnectiviteit, zal niet enkel de kaart op
                je scherm veranderen, maar ook deze kleurenbalk, zodat je de kleuren en scores ziet die aan de basis
                liggen van deze kaart.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>
                  De indeling is op basis van kwantielen. In het 'Meer info'-venster van de Walkabilityscore bij 'De
                  Berekening: scores en kleuren tonen het resultaat', lees je alles hierover.
                </strong>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>Herschaal</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph>
                <strong>
                  Zodra je de HERSCHAAL-functie activeert, zal de tool de walkabilityscores van alle door jou
                  geselecteerde hectarecellen met elkaar vergelijken en opnieuw inkleuren.
                </strong>
              </Typography>
              <Typography variant="body1" paragraph>
                Er zijn twee mogelijkheden:
              </Typography>
              <ol>
                <li>
                  <strong>
                    {" "}
                    De tool vergelijkt alle hectarecellen op jouw scherm met elkaar en kleurt ze opnieuw in.{" "}
                  </strong>
                  <br />
                  Zo zie je hoe de buurten op jouw scherm ten opzichte van elkaar scoren.
                  <br />
                  Gebruik steeds de Refresh-knop <RefreshIcon style={{ width: 15, height: 15 }} /> als je in- en
                  uitzoomt opdat de kleurenbalk zich mee aanpast.
                </li>
                <li>
                  <strong>Gebruik de HERSCHAAL-functie nadat je een BELFIUS-CLUSTER selecteerde.</strong> De tool zal nu
                  alle hectarecellen van de door jou geselecteerde Belfius-cluster met elkaar vergelijken en opnieuw
                  inkleuren. Nu zie je hoe jouw buurt scoort ten opzichte van de buurten uit gelijkaardige gemeenten.
                </li>
              </ol>
              <Typography variant="body1" paragraph>
                Hou er rekening mee dat de 'herschaling' steeds gebeurt aan de hand van de kaart die je activeerde: de
                Walkabilityscore, de Walkabilityscore bebouwd gebied, Stratenconnectiviteit, Woondichtheid of
                functiemix.
              </Typography>
              <Typography variant="body1" paragraph>
                Wil je exacter weten hoe dit in zijn werk gaat? Alle info vind je in het 'Meer info'-venster
                Walkabilityscore onder 'De berekening: scores en kleuren tonen het resultaat' en 'Activeer steeds de
                HERSCHAAL-functie'.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </DialogContent>
    </>
  );
}
