import { Box, DialogContent, Typography } from "@material-ui/core";

import { AnalyseIcon } from "../../assets/custon-icons/AnalyseIcon";
import { Accordion, AccordionSummary, AccordionDetails, InformationDialogTitle } from "../InformationDialog";

export function WalkabilityIndicatorInformation() {
  return (
    <>
      <InformationDialogTitle>Meer info over de Walkabilityscore-tool</InformationDialogTitle>
      <DialogContent>
        <Typography variant="h3" gutterBottom>
          Walkability
        </Typography>

        <div>
          <Accordion>
            <AccordionSummary>
              <Typography>Een gezonde publieke ruimte die aanzet om meer te bewegen</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph>
                Meer stappen of wandelen is één van de eenvoudigste veranderingen die je kan doen aan je levensstijl om
                je gezondheid te verbeteren. Onderzoek toont een schat aan gezondheidsvoordelen. De walkability van je
                buurt verbeteren is een effectieve en praktische strategie die je daarvoor kan inzetten.
              </Typography>
              <Typography variant="body1" paragraph>
                Walkability verwijst naar de mate waarin je, op 15 minuten tijd, via straten en doorsteken al wandelend
                dagdagelijkse voorzieningen kan bereiken, zoals de bakker, supermarkt, dokter, school, werk, groene
                plekken … Hoe meer straten verbonden zijn met elkaar, hoe meer soorten voorzieningen er zijn, en hoe
                meer mensen er in die buurt wonen, hoe hoger een buurt scoort op walkability. Ook mobiliteitskeuzes, de
                kwaliteit en inrichting van de publieke ruimte … hebben invloed op de walkability.
              </Typography>
              <Typography variant="body1" paragraph>
                Volgens internationale én Vlaamse studies is een beweegvriendelijke publieke ruimte een grote troef voor
                onze gezondheid en maatschappij. In buurten met een hoge walkabilityscore stappen volwassenen gemiddeld
                80 minuten per week meer dan in buurten met een lage walkability. Voor ouderen is dat gemiddeld 50
                minuten. Ook op ons fietsgedrag heeft een buurt met een hoge walkabilityscore zijn impact: volwassenen
                fietsen er 40 minuten meer per week als functionele verplaatsing. Dat heeft ook invloed op de modal
                shift: 36 minuten per week minder gemotoriseerd transport (Van Dyck et al., 2010). Bij jongeren en
                ouderen zien we dat een hogere walkabilityscore vooral in kwetsbare buurten een positieve invloed heeft
                op actieve verplaatsingen. Bijkomende voordelen zijn minder kosten voor het openbaar vervoer, meer
                inkomsten voor de lokale handel en betere bloeddrukwaarden.
              </Typography>
              <Typography variant="body1" paragraph>
                Bewegen in de publieke ruimte kan zowel in de vrije tijd als tijdens actieve verplaatsingen. Op dat
                laatste heeft walkability het grootste effect.
              </Typography>
              <Typography variant="body1" paragraph>
                Om de publieke ruimte beweegvriendelijk te maken, is er zowel op plannings- en beleidsniveau aandacht
                nodig als op inrichtingsniveau (zie figuur).
              </Typography>

              <Box py={2}>
                <img src="/images/schema.png" alt="schema" />
              </Box>

              <Typography variant="body1" paragraph>
                De walkabilityscore-tool situeert zich op plannings- en beleidsniveau. Hij helpt je om buurten te
                onderzoeken en aan te duiden waar veranderingen prioritair zijn vanuit gezondheidsperspectief, en je
                keuze te onderbouwen.
              </Typography>
              <Typography variant="body1" paragraph>
                Neem zeker ook een kijkje op de website{" "}
                <a
                  href="https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/walkability-tool"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gezonde publieke ruimte
                </a>
                : daar vind je meer ruimtelijke strategieën die je kan inzetten voor beweging en voor andere
                gezondheidsthema's en beleidsdomeinen.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>Wil je graag inzetten op het verhogen van walkability?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph>
                Ga met deze unieke walkabilityscore-tool na of een buurt meer of minder potentieel heeft om in te zetten
                op walkability en om dagelijkse actieve verplaatsingen en recreatie te stimuleren. Bekijk de
                walkabilityscore op de kaart, analyseer de score, vergelijk je buurt met andere buurten ... Via extra
                kaartlagen kan je zien waar belangrijke voorzieningen voor kwetsbare groepen zich bevinden (zoals
                assistentiewoningen en kinderopvang), waar mogelijkheden zijn om wandelen te combineren met openbaar
                vervoer ... In één oogopslag zie je het wandel- en fietsbereik van zowel kinderen, jongeren, volwassenen
                als ouderen en minder mobiele ouderen in jouw buurt.
              </Typography>
              <Typography variant="body1" paragraph>
                De walkabilityscore-tool biedt zo heel wat mogelijkheden om mee te bouwen aan een beweegvriendelijke
                omgeving en{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.gezondleven.be/projecten/gezonde-publieke-ruimte-1"
                >
                  gezonde publieke ruimte
                </a>{" "}
                voor iedereen.
              </Typography>
              <Typography variant="body1" paragraph>
                Samen met experten zoals je plaatselijke Logo, ruimtelijk planners, mobiliteitsdeskundigen en ontwerpers
                publieke ruimte kan je, aan de hand van de kaarten en de walkabilityscores die je zelf bekeek, de
                opportuniteiten opzoeken voor duurzame ontwikkelingsdoelstellingen, gezondheidsbeleid, sociaal beleid en
                kwetsbare doelgroepen, voor zorgzame buurten, kindervriendelijke netwerken ...
              </Typography>
              <Typography variant="body1" paragraph>
                GIS-experten kunnen de data zelfs downloaden naar een geografisch informatiesysteem. Zo kan je samen, op
                maat van jouw buurt of gemeente nog verdergaande analyses uitvoeren en aandachtspunten aanpakken.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>
                  Wil je meer weten over de walkabilityscore-tool en de manier waarop je je inzichten kan interpreteren
                  en inzetten? Neem dan zeker een kijkje op de{" "}
                  <a
                    href="https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/walkability-tool"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    website
                  </a>
                  .
                </strong>
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>
                  Of ben je nieuwsgierig naar de berekeningswijze en data, veelgestelde vragen, of nog meer info over
                  hoe je met de tool aan de slag kan gaan?
                </strong>{" "}
                <a
                  href="https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/walkability-tool#materialen"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Hier
                </a>{" "}
                vind je alles daarover.
              </Typography>

              <Typography variant="body1" paragraph>
                Daarnaast kan je steeds terecht bij het{" "}
                <a href="https://www.vlaamse-logos.be/" rel="noopener noreferrer" target="_blank">
                  Lokaal Gezondheidsoverleg
                </a>{" "}
                (Logo) voor ondersteuning op maat. Zij helpen je ook graag verder met de planning en inrichting van een
                Gezonde Publieke Ruimte.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <Box pt={4} pb={1}>
          <Typography variant="h3" gutterBottom>
            Walkabilityscore
          </Typography>
        </Box>

        <Accordion>
          <AccordionSummary>
            <Typography>De berekening: scores en kleuren tonen het resultaat </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              De walkabilityscore is berekend voor het volledige grondgebied van Vlaanderen en het Brussels
              hoofdstedelijk Gewest (BHG). De score bundelt drie aspecten: de stratenconnectiviteit, de woondichtheid en
              de functiemix. De stratenconnectiviteit telt daarbij dubbel.
            </Typography>
            <Typography variant="body1" paragraph>
              Voor elke hectarecel in Vlaanderen en het Brussels Hoofdstedelijk Gewest is, in een straal van 1 km,
              berekend hoe goed die hectarecel scoort op stratenconnectiviteit, woondichtheid en functiemix (op enkele
              hectarecellen na die te ver gelegen zijn van een weg).
            </Typography>
            <Typography variant="body1" paragraph>
              Hoe hoger de score op die drie aspecten, hoe hoger de walkabilityscore.
            </Typography>
            <Typography variant="body1" paragraph>
              De score per hectarecel wordt getoond aan de hand van kleuren op een kaart (elke hectarecel/pixel - krijgt
              een kleur) en een scoregetal. Als je de kaart Walkabilityscore opent, zie je de score van alle
              hectarecellen in Vlaanderen en het Brussels Hoofdstedelijk Gewest.
            </Typography>
            <Typography variant="body1" paragraph>
              De walkabilityscore is een relatieve score. Dat komt omdat ze berekend is{" "}
              <strong>ten opzichte van</strong> alle hectarecellen in Vlaanderen en het Brussels Hoofdstedelijk Gewest.
            </Typography>
            <Typography variant="body1" paragraph>
              <i>
                De indeling van de walkabilityscore gebeurt aan de hand van kwantielen en wordt vervolgens gekoppeld aan
                kleuren. Dit betekent dat alle scores ingedeeld zijn in 4 groepen: de 25% hoogste, 25% bovengemiddeld,
                25% benedengemiddeld en de 25% laagste. Aan de hand van kleuren worden de scores gevisualiseerd van
                donkerblauw voor de hoogste scores tot rood voor de laagste scores, zoals je hier ziet in de kleurenbalk
                en op de kaart.
              </i>
            </Typography>
            <Typography variant="body1" paragraph>
              <i>
                Wanneer er vooral veel lage scores zijn, zoals in heel wat buurten in Vlaanderen, zorgt dat ervoor dat
                de gemiddelde score <strong>voor Vlaanderen</strong> ook negatief is. Daardoor kunnen gebieden met een
                relatief lage score (negatieve score) toch bovengemiddeld scoren en dus een blauwe kleur krijgen. Het
                merendeel van de hectarecellen in Vlaanderen is immers gelegen in gebieden met een lage
                inwonersdichtheid, lage stratenconnectiviteit en/of lage functiemix. Er is slechts een kleine groep die
                hoog scoort op walkability.{" "}
              </i>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>
                Gebruik daarom steeds de HERSCHAAL-functie om je buurt te bekijken en buurten op lokaal niveau onderling
                te vergelijken. De kleuren passen zich dan aan jouw selectie aan.
              </strong>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>De HERSCHAAL-functie vind je in het LEGENDE-venster (linksonder op de kaart). </strong>
            </Typography>

            <Box>
              <img src={`/images/herschaal-legende-1.png`} alt="Herschaal legende 1" />
              <img src="/images/herschaal-legende-2.png" alt="Herschaal legende 2" />
            </Box>
            <Typography variant="body1" paragraph>
              In het document{" "}
              <a
                href="https://www.gezondleven.be/files/beweging/Berekeningswijze-en-data-walkabilityscoretool.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Berekeningswijze en data
              </a>{" "}
              vind je alle info terug die aan de basis ligt van de berekening.
            </Typography>
            <Typography variant="body1" paragraph>
              Alle info over het gebruik van de tool en de wijze waarop je de gegevens kan analyseren en interpreteren
              vind je op de{" "}
              <a
                href="https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/walkability-tool"
                target="_blank"
                rel="noopener noreferrer"
              >
                website
              </a>
              .
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary>
            <Typography>De walkabilityscore: 5 kaarten</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              De walkabilityscore kan je raadplegen aan de hand van deze 5 kaarten. Ze tonen je de score van elke
              hectarecel in Vlaanderen en het Brussels Hoofdstedelijk Gewest (BHG).
            </Typography>
            <Typography variant="body1" paragraph>
              Afhankelijk van de kaart die je activeert kan je (1) de walkabilityscore bekijken voor Vlaanderen en het
              Brussels Hoofdstedelijk Gewest, (2) de walkabilityscore bebouwd gebied activeren of de 'drie aspecten'
              analyseren die aan de basis liggen van de berekening van de walkabilityscore, zijnde: (3) de
              stratenconnectiviteit, (4) de woondichtheid en (5) de functiemix.
            </Typography>
            <Typography variant="body1" paragraph>
              Aan de hand van de kleuren op de kaart, het venster ANALYSE-INFO (linksboven op de kaart) en het LEGENDE
              venster (linksonder op de kaart) zie je, na een klik op de kaart van je keuze, hoe jouw buurt scoort ten
              opzichte van alle andere buurten in Vlaanderen en het Brussels Hoofdstedelijk Gewest.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>
                Gebruik daarna steeds de HERSCHAAL-functie in het LEGENDE-venster om je buurt te bekijken en buurten op
                lokaal niveau onderling te vergelijken. De kleuren passen zich dan aan jouw selectie aan.
              </strong>
            </Typography>

            <Box>
              <img src="/images/herschaal-bree-1.png" alt="Herschaal Bree 1" />
              <img src="/images/herschaal-bree-2.png" alt="Herschaal Bree 2" />
            </Box>
            <Typography variant="caption" paragraph>
              Een printscreen uit de Walkabilityscore-tool van de gemeente Bree, links in beeld de kaart
              Stratenconnectiviteit waarop je de vergelijking ziet ten opzichte van alle hectarecellen in Vlaanderen en
              het Brussels Hoofdstedelijk Gewest, rechts in beeld hetzelfde gebied na het activeren van de
              HERSCHAAL-functie. Het dichte stratenpatroon van de stadskern scoort duidelijk hoog, net als dat van de
              kern van Bocholt, bovenaan op de kaart.
            </Typography>
            <Typography variant="body1" paragraph>
              Wil je meer weten over de HERSCHAAL-functie? In het onderste item (‘Activeer steeds de HERSCHAAL-functie')
              lees je er alles over.
            </Typography>
            <Typography variant="body1" paragraph>
              Wil je weten op welke wijze de kaarten tot stand zijn gekomen? In het document{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gezondleven.be/files/beweging/Berekeningswijze-en-data-walkabilityscoretool.pdf"
              >
                Berekeningswijze en data
              </a>{" "}
              vind je alle info terug die aan de basis ligt van deze kaarten.
            </Typography>

            <Accordion>
              <AccordionSummary>
                <Typography>Walkabilityscore</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  De kaart Walkabilityscore toont je de walkabilityscores voor álle hectarecellen in Vlaanderen en het
                  Brussels Hoofdstedelijk Gewest (BHG) – op enkele na die te ver gelegen zijn van de weg.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <Typography>Walkabilityscore bebouwd gebied</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  Standaard opent de Walkabilityscore-tool eerst de kaart Walkabilityscore bebouwd gebied. Waarom? Omdat
                  de focus van walkability gericht is op de bebouwde omgeving (onze woon- en werkomgeving).
                </Typography>
                <Typography variant="body1" paragraph>
                  De kaart Walkabilityscore bebouwd gebied is gebaseerd op de walkabilityscore van Vlaanderen en het
                  Brussels Hoofdstedelijk Gewest (BHG). Ze visualiseert echter enkel de hectarecellen die gelegen zijn
                  in ‘bebouwd gebied' en past de kleuren daarop aan.
                  <br />
                  De scores zijn dus identiek aan die van de kaart Walkability. Alleen worden alle hectarecellen die
                  gelegen zijn in het bebouwd gebied nu onderling vergeleken en passen de kleuren zich daarop aan.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <Typography>Stratenconnectiviteit</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  Stratenconnectiviteit voor voetgangers en fietsers vertrekt vanuit de mogelijkheid om buurten vlot met
                  elkaar te verbinden via straten.
                </Typography>
                <Typography variant="body1" paragraph>
                  De berekening van de stratenconnectiviteit gaat uit van het aantal kruispunten dat voor voetgangers
                  bereikbaar is in een straal van 1 km. Want hoe meer bereikbare kruispunten, hoe meer kans om een
                  kortere verbinding te maken tussen twee locaties.
                </Typography>
                <Typography variant="body1" paragraph>
                  Een hogere stratenconnectiviteit draagt bij aan een hogere walkabilityscore.
                </Typography>
                <Typography variant="body1" paragraph>
                  Ruimtelijke planners spreken ook over ‘doorwaadbaarheid' in plaats van stratenconnectiviteit. Net
                  zoals water al dan niet doorwaadbaar is, kan je je buurt doorwaadbaar maken via een netwerk van wegen,
                  steegjes door bouwblokken, aansluitingen op trage wegen …
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>
                    <a
                      href="https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/walkability-tool#stratenconnectiviteit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hier
                    </a>{" "}
                    vind je meer info om de stratenconnectiviteit voor voetgangers en fietsers te verhogen.
                  </strong>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <Typography>Woondichtheid</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  In een buurt waar veel mensen wonen is het extra belangrijk om in te zetten op een goede walkability.
                  Hoe meer mensen er wonen, hoe meer mensen gebruik maken van de voorzieningen in die buurt.
                </Typography>
                <Typography variant="body1" paragraph>
                  De woondichtheid is berekend aan de hand van het aantal inwoners die wonen in een straal van 1 km.
                </Typography>
                <Typography variant="body1" paragraph>
                  Een hogere woondichtheid draagt bij aan een hogere walkabilityscore.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <Typography>Functiemix</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  De functiemix verwijst naar de mate waarin er verschillende functies en voorzieningen gemengd
                  voorkomen in de buurt.
                </Typography>

                <Typography variant="body1" paragraph>
                  Voor de functiemix is berekend hoe divers iedere buurt is aan de hand van de aanwezigheid van deze 7
                  types functies en de oppervlakte die zij innemen binnen een straal van 1 km:
                </Typography>
                <ol>
                  <li>Detailhandelszaken - voeding ('food')</li>
                  <li>Detailhandelszaken - algemeen ('non-food')</li>
                  <li>Instellingen zoals scholen, kinderopvang, ziekenhuizen …</li>
                  <li>Recreatie zoals bibliotheken, cultuurcentra, bioscopen, musea …</li>
                  <li>Sportaccommodatie en wijkgroen</li>
                  <li>Kantoren met meer dan 20 werknemers</li>
                  <li>Woningen ('residentieel')</li>
                </ol>
                <Typography variant="body1" paragraph>
                  Hoe diverser het aanbod, hoe hoger de score voor de functiemix. Een hogere functiemix zal bijdragen
                  aan een hogere walkabilityscore.
                </Typography>
                <Typography variant="body1" paragraph>
                  Mogelijke maatregelen om de functiemix te verhogen vind je{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.gezondleven.be/settings/gezonde-gemeente/gezonde-publieke-ruimte/walkability-tool#functiemix"
                  >
                    hier
                  </a>{" "}
                  op de website. .
                </Typography>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Je kan de scores ook individueel raadplegen of onderling vergelijken.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              Door een klik op de kaart kan je voor die hectarecel die je aanklikte zien wat de walkabilityscore is.
              Info over de score vind je in de vensters LEGENDE (linksonder op de kaart) en ANALYSE-INFO (linksboven op
              de kaart). Wil je in detail bekijken welke aspecten invloed hebben op de walkabilityscore, klik dan op de
              knop Analyse (rechts op je scherm).
            </Typography>
            <Typography variant="body1" paragraph>
              Zodra je deze knop <AnalyseIcon style={{ width: 15 }} /> Analyse activeert, kan je meerdere locaties
              aanklikken op de kaart en onderling vergelijken.
            </Typography>
            <Typography variant="body1" paragraph>
              Door op de kaart in te zomen, kan je de schaal aanpassen. Klik je vervolgens op de knop Herschaal in het
              LEGENDE venster, krijg je een vergelijking van alle hectarecellen die op dat ogenblik op jouw kaartbeeld
              zichtbaar zijn.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Activeer steeds de HERSCHAAL-functie </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              Om je buurt te bekijken en buurten te vergelijken is het belangrijk dat je steeds de HERSCHAAL-functie
              activeert.
            </Typography>
            <Typography variant="body1" paragraph>
              De walkabilityscore is immers een relatieve score, omdat ze alle hectarecellen in Vlaanderen en het
              Brussels Hoofdstedelijk Gewest (BHG) met elkaar vergelijkt. Een vergelijking op niveau van jouw buurt is
              echter veel interessanter en geeft een correcter beeld.
            </Typography>
            <Typography variant="body1" paragraph>
              <i>Waarom?</i>
              <br />
              <strong>
                Zodra je de HERSCHAAL-functie activeert, zal de tool de walkabilityscores van alle door jou
                geselecteerde hectarecellen met elkaar vergelijken en opnieuw inkleuren.{" "}
              </strong>
            </Typography>
            <Typography variant="body1" paragraph>
              <i>Hoe doe je dat?</i>
              <br />
              <strong>Er zijn twee mogelijkheden:</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>1. Zoom in op de buurten die je wil vergelijken.</strong> Activeer daarna de HERSCHAAL-functie
              door daarop te klikken. De tool zal nu alle hectarecellen op jouw scherm met elkaar vergelijken en opnieuw
              inkleuren. Ook de kleurenbalken passen zich aan. Zo zie je hoe de buurten op jouw scherm ten opzichte van
              elkaar scoren.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>2. Vergelijk je buurt met buurten uit gelijkaardige gemeenten.</strong> Selecteer een
              BELFIUS-CLUSTER (links op je scherm) en activeer daarna de HERSCHAAL-functie. De tool zal nu alle
              hectarecellen van de door jou geselecteerde Belfius-cluster met elkaar vergelijken en opnieuw inkleuren.
              Ook hier passen de kleurenbalken zich aan. Nu zie je hoe jouw buurt scoort ten opzichte van de buurten uit
              gelijkaardige gemeenten.
            </Typography>
            <Typography variant="body1" paragraph>
              Je vindt de HERSCHAAL-functie in het LEGENDE-venster. Informatie over de Belfius-cluster waarin je buurt
              gelegen is, vind je terug in het ANALYSE-INFO-venster. (Deze vensters bevinden zich linksboven op de
              kaart)
            </Typography>

            <Box>
              <img src="/images/herschaal-boom-1.png" alt="Herschaal Boom 1" />
              <img src="/images/herschaal-boom-2.png" alt="Herschaal Boom 2" />
            </Box>

            <Typography variant="caption" paragraph>
              Een printscreen uit de Walkabilityscore-tool: links de Walkabilityscore bebouwd gebied ingezoomd op de
              gemeente Boom en haar omgeving, rechts in beeld hetzelfde gebied na het activeren van de
              HERSCHAAL-functie. Bovenaan links op de kaart zie je het ANALYSE-INFO-venster met informatie over de
              Belfius-cluster waarin de gemeente Boom is gelegen. Het ANALYSE-INFO-venster verschijnt na een klik op de
              kaart.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </>
  );
}
