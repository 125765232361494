import { AtRule } from "csstype";
import { WMSTileLayerProps } from "react-leaflet";
import { Point, LatLng, LatLngBounds } from "leaflet";
import { AxiosRequestConfig } from "axios";
import { InformationSubject } from "../components/InformationDialog";

export type JSSFontface = AtRule.FontFace;

export interface MapPosition {
  lat: number;
  lng: number;
  zoom: number;
  type: "map" | "search";
  boundingBox?: BoundingBox;
}

export interface HistoryState {
  download?: {
    config: AxiosRequestConfig;
    visualName: string;
    filename?: string;
  };
  informationDialogSubject?: InformationSubject;
}

export interface APIError {
  key: string;
  message: string;
  arguments: string[];
}

export interface BoundingBox {
  LowerLeft: {
    Lat_WGS84: number;
    Lon_WGS84: number;
    X_Lambert72: number;
    Y_Lambert72: number;
  };
  UpperRight: {
    Lat_WGS84: number;
    Lon_WGS84: number;
    X_Lambert72: number;
    Y_Lambert72: number;
  };
}

export interface Baselayer {
  key: string;
  title: string;
  description?: string;
  isActive: boolean;
  attribution?: string;
  url: string;
  image: string;
}

export interface SpatialOverlayer extends BaseOverlayer {
  includeInComparison: boolean;

  // extra layers in Guppy to fetch point analysis data
  extraDataLayers: Array<{
    layer: string;
    label: string;
  }>;
}

type DemographicOverlayerCategory = "aandeel" | "aantal";
export type DemographicOverlayer = BaseOverlayer & {
  category?: DemographicOverlayerCategory;
};

type ExtraInfoOverlayerCategory = "gemeentegrenzen" | "voorzieningen" | "mobiliteit" | "synthesekaarten";
export type ExtraInfoOverlayer = BaseOverlayer & {
  category: ExtraInfoOverlayerCategory;
};

// Type to extend from
export interface BaseOverlayer {
  key: string;
  name: string;
  wmsLayer: {
    tiled?: boolean;
    cql_filter?: string;
    layers: string;
  } & WMSTileLayerProps;
  isActive: boolean;
}

export interface BelfiusOverlayer extends BaseOverlayer {
  cluster: string;
}

export interface MyMapClick {
  x: number;
  y: number;
  mapSize: Point;
  bbox: LatLngBounds;
  latLng: LatLng;
  markerColor?: string;
}

export interface MinMax {
  min: number;
  max: number;
}

export interface WalkingDistance {
  label: string;
  distance: number;
}

export enum ZoneDistanceKey {
  walkingDistance = "walkingDistance",
  bikeDistance = "bikeDistance",
  electricBikeDistance = "electricBikeDistance",
}

export interface ZoneDistance {
  distance: number;
  isActive?: boolean;
}
