import { RasterQuantiles } from "guppy.ts";
import { useQuery, UseQueryOptions } from "react-query";
import { BaseOverlayer, BelfiusOverlayer } from "../types";
import { loadQuantiles } from "../utils/belfius-clusters.utils";

export function useBelfiusQuantiles(
  belfiusOverlayer: BelfiusOverlayer | undefined,
  overlayer: BaseOverlayer,
  options?: UseQueryOptions<RasterQuantiles[] | null, Error, RasterQuantiles[] | null>
) {
  async function queryFn() {
    if (!belfiusOverlayer) return null;
    const quantiles = loadQuantiles(overlayer.wmsLayer.layers, belfiusOverlayer?.cluster);
    return quantiles;
  }

  return useQuery<RasterQuantiles[] | null, Error>(
    ["belfius-quantiles", overlayer.key, belfiusOverlayer?.key],
    queryFn,
    options
  );
}
