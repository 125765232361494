import Axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useEffect, useRef, useState } from "react";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import { APIError, HistoryState } from "../types";

interface Props {}

/**
 * The download component acts upon the history.state 'download' property
 */
export function DownloadFileBlob(props: Props) {
  const history = useHistory<HistoryState>();
  const location = useLocation<HistoryState>();
  const linkRef = useRef<HTMLAnchorElement>(null!!);
  const [contentDispositionName, setContentDispositionName] = useState<string | null>(null);
  const [downloadState, setDownloadState] = useState<HistoryState["download"] | null>(null);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const { data: downloadResponse, isLoading } = useDownload(downloadState?.config!, {
    enabled: !!downloadState,
  });

  // set download data base on new history state
  useEffect(() => {
    if (location.state?.download) setDownloadState(location.state.download);
  }, [location.state]);

  useEffect(() => {
    if (!downloadResponse) return;

    // create blob link to download
    const objectUrl = window.URL.createObjectURL(
      new Blob([downloadResponse.data], {
        type: downloadResponse.headers["content-type"],
      })
    );
    setDataUrl(objectUrl);

    const filename = getFilenameFromResponse(downloadResponse);
    if (filename) setContentDispositionName(filename);

    setTimeout(() => {
      linkRef.current.click();
      setDownloadState(null);
    }, 1);
  }, [downloadResponse, history, location]);

  return render();

  function render() {
    const downloadText = downloadState?.visualName ? downloadState.visualName : "Downloading...";

    return (
      <StyledBackdrop open={isLoading}>
        <CircularProgress color="inherit" style={{}} />
        <Typography variant="body1" display="block" gutterBottom>
          {downloadText}
        </Typography>
        <a
          href={dataUrl || ""}
          ref={linkRef}
          style={{ display: "none" }}
          key={location.state?.download?.filename}
          download={downloadState?.filename || contentDispositionName}
        >
          Download
        </a>
      </StyledBackdrop>
    );
  }
}

const StyledBackdrop = styled(Backdrop)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)}px;
  z-index: 99999;

  .MuiTypography-root {
    color: white;
  }

  .MuiCircularProgress-circle {
    stroke: white;
  }
`;

function getFilenameFromResponse(response: AxiosResponse): string | null {
  const contentDisposition = response.headers["content-disposition"];
  const filename = contentDisposition.match(/filename=([^,;]+);?/);

  if (filename[1]) return filename[1];
  else return null;
}

export function useDownload(
  config: AxiosRequestConfig,
  options?: UseQueryOptions<
    AxiosResponse<Blob>,
    AxiosError<APIError[]>,
    AxiosResponse<Blob>,
    string | Array<string | number>
  >
) {
  async function queryFn(config: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
    const result = await Axios(config);
    return result;
  }
  return useQuery(["downloads", JSON.stringify(config)], () => queryFn(config), {
    retry: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    suspense: false,
    ...options,
  });
}
