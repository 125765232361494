import { DialogContent, Typography } from "@material-ui/core";
import { InformationDialogTitle } from "../InformationDialog";

export function AnalyseInformation() {
  return (
    <>
      <InformationDialogTitle>Meer info over de analyse</InformationDialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Door een klik op de kaart kan je nagaan wat de walkabilityscore is voor de door jou gekozen locatie. Aan de
          hand van de kleurenbalken kan je zien welke invloed de stratenconnectiviteit, woondichtheid en functiemix op
          jouw locatie hebben. Je kan meerdere locaties aanklikken en met elkaar vergelijken. Met het vuilbakje kan je
          een locatie verwijderen.
        </Typography>
        <Typography variant="body1" paragraph>
          Activeer je een andere kaart, bv. stratenconnectiviteit, dan zal je merken dat enkel de kaart zich aanpast en
          de kleurenbalken dezelfde blijven.
        </Typography>
        <Typography variant="body1" paragraph>
          Activeer je de HERSCHAAL-functie, dan zullen de scores blijven maar de kleuren zich aanpassen aan jouw
          selectie.
        </Typography>
        <Typography variant="body1" paragraph>
          Wil je exacter weten hoe dat in zijn werk gaat? Alle info vind je in het 'Meer info'-venster Walkabilityscore
          onder 'De berekening: scores en kleuren tonen het resultaat' en 'Activeer steeds de HERSCHAAL-functie'.
        </Typography>
        <Typography variant="body1" paragraph>
          Als je de info raadpleegt, zal je merken dat de Walkabilityscore en Walkabilityscore bebouwd gebied altijd
          dezelfde score hebben. Dat komt omdat de Walkabilityscore bebouwd gebied enkel die hectarecellen visualiseert
          die gelegen zijn in het bebouwd gebied. Verschijnt er geen info?
        </Typography>
        <Typography variant="body1" paragraph>
          Als er geen informatie verschijnt, klikte je op een locatie buiten het bebouwd gebied. Wil je de score kennen,
          klik dan op de kaart Walkabilityscore of kijk op de kleurenbalk Walkabilityscore. Zie je nog geen score? Dan
          wil dat zeggen dat je op een locatie klikte die te ver van een weg is gelegen.
        </Typography>
      </DialogContent>
    </>
  );
}
