export const ZoneDistanceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28.361" height="27.156" viewBox="0 0 28.361 27.156">
    <g id="Group_938" data-name="Group 938" transform="translate(-1451.32 -256.922)">
      <circle
        id="Ellipse_60"
        data-name="Ellipse 60"
        cx="3.5"
        cy="3.5"
        r="3.5"
        transform="translate(1462 267)"
        fill="#2c2a2a"
      />
      <path
        id="Path_636"
        data-name="Path 636"
        d="M1463.3,263.337l18.189,18.113"
        transform="translate(-2.897 -5.295)"
        fill="none"
        stroke="#2c2a2a"
        strokeWidth="2"
      />
      <path
        id="Path_637"
        data-name="Path 637"
        d="M1457.679,268l18.569,18.568"
        transform="translate(-5.652 -3.278)"
        fill="none"
        stroke="#2c2a2a"
        strokeWidth="2"
      />
      <path
        id="Path_638"
        data-name="Path 638"
        d="M1459.719,281.215l17.208-17.208"
        transform="translate(-2.595 -2.115)"
        fill="none"
        stroke="#2c2a2a"
        strokeWidth="2"
      />
      <path
        id="Path_639"
        data-name="Path 639"
        d="M1459.4,280.948l8.273-8.273,8.273-8.273"
        transform="translate(-6.36 -6.773)"
        fill="none"
        stroke="#2c2a2a"
        strokeWidth="2"
      />
      <path
        id="Path_640"
        data-name="Path 640"
        d="M1459.4,281.157l8.378-8.377,8.378-8.377"
        transform="translate(2.82 2.214)"
        fill="none"
        stroke="#2c2a2a"
        strokeWidth="2"
      />
    </g>
  </svg>
);
