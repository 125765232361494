import Axios from "axios";
import { MinMax, BaseOverlayer } from "../types";
import { useQuery } from "react-query";
import { ColorMap } from "marvin-ui-kit";

interface FetchLegendResponse {
  data: {
    colorMap: ColorMap;
    sld: string;
  };
  meta: {
    count: number;
    totalCount: number;
    type: string;
  };
}

export function getLegendUrl(minMax: MinMax, layer: BaseOverlayer): string {
  return `/api/legend?min=${minMax.min}&max=${minMax.max}&name=${layer.wmsLayer.layers}`;
}

const fetchLegend = async (minMax: MinMax | undefined, layer: BaseOverlayer) => {
  if (!minMax) {
    return null;
  }
  const sldUrl = getLegendUrl(minMax, layer);
  const response = await Axios.get<FetchLegendResponse>(sldUrl);
  return response.data;
};

export const useLegend = (minMax: MinMax | undefined, layer: BaseOverlayer) => {
  return useQuery(["legend", layer, minMax], () => fetchLegend(minMax, layer));
};
