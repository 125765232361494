import { ListItem, Tooltip } from "@material-ui/core";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { AnalyseIcon } from "../../assets/custon-icons/AnalyseIcon";

export const ListItemAnalyse = () => {
  const { activeAction } = useParams<{ activeAction: string }>();
  const history = useHistory();

  function getRoute(route: string) {
    if (history.location.pathname === route) {
      return "/";
    }
    return route;
  }

  return (
    <Tooltip title="Analyse">
      <ListItem
        className="list-item"
        selected={activeAction === "analyse"}
        component={NavLink}
        to={{ ...history.location, pathname: getRoute(`/analyse`) }}
        button
      >
        <AnalyseIcon />
      </ListItem>
    </Tooltip>
  );
};
