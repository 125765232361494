import { Fragment, Suspense } from "react";
import { GeoJSON, Pane, WMSTileLayer } from "react-leaflet";
import { useAtom } from "jotai";

import { MyMapClick } from "../../types";
import { useMarkers, useWalkingDistance, useWalkingDistanceLink } from "../../hooks";
import { Spinner } from "../Spinner";
import { zoneDistanceAtom } from "../../atoms/zoneDistance.atom";

export const MapWalkingDistance = () => {
  const [marker] = useMarkers();

  const [zoneDistances] = useAtom(zoneDistanceAtom);

  return render();

  function render() {
    if (!marker) return null;

    return (
      <Suspense fallback={<Spinner />}>
        {zoneDistances.electricBikeDistance.isActive && (
          <WalkingDistanceGeoJSON
            marker={marker}
            zoneDistance={zoneDistances.electricBikeDistance.distance}
            opacity={0.5}
            zoneColor="white"
            zIndex={300}
          />
        )}

        {zoneDistances.bikeDistance.isActive && (
          <WalkingDistanceGeoJSON
            marker={marker}
            zoneDistance={zoneDistances.bikeDistance.distance}
            opacity={0.5}
            zoneColor="#B4B4B4"
            zIndex={400}
          />
        )}
        {zoneDistances.walkingDistance.isActive && (
          <WalkingDistanceGeoJSON
            marker={marker}
            zoneDistance={zoneDistances.walkingDistance.distance}
            opacity={0.2}
            zoneColor="black"
            zIndex={500}
          />
        )}
      </Suspense>
    );
  }
};

interface Props {
  marker: MyMapClick;
  zoneDistance: number;
  opacity: number;
  zoneColor: string;
  zIndex: number;
}

function WalkingDistanceGeoJSON(props: Props) {
  const { marker, zoneDistance, opacity, zoneColor, zIndex } = props;

  const { data: distanceLines } = useWalkingDistanceLink(marker.latLng, zoneDistance);

  const { data: distance } = useWalkingDistance(marker.latLng, zoneDistance);

  if (!distanceLines || !distance) {
    return null;
  }

  const WMSTileLayerConfig = {
    url: "/geoserver/wms",
    layers: "walkability:network_view",
    format: "image/png",
    transparent: true,
    opacity: 0.7,
    tiled: true,
    viewparams: `query_id:${distanceLines.link_id};segment:${distanceLines.segment}`,
  };

  return (
    <Fragment>
      <Pane name={`tile-${zIndex}`} style={{ zIndex: 599 }}>
        <WMSTileLayer key={`${marker.latLng}${zoneDistance}`} {...WMSTileLayerConfig} />
      </Pane>

      <Pane name={`pane-${zIndex}`} style={{ zIndex }}>
        <GeoJSON
          key={`${marker.latLng}${zoneDistance}false`}
          data={{ ...distance, type: "Polygon" }}
          style={{
            color: "transparent",
            fillColor: zoneColor,
            fillOpacity: opacity,
          }}
        />
      </Pane>
    </Fragment>
  );
}
