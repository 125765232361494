import { ColorMap } from "marvin-ui-kit";
import styled from "styled-components";

export interface Props {
  colorMap: ColorMap;
  className?: string;
  reverseLabels?: boolean;
  height: number;
}

const defaultProps = {
  height: 150,
};

const LegendRamp = (props: Props) => {
  const { colorMap, className, reverseLabels } = props;

  const { height } = props;

  return (
    <Styles className={className} reverse={reverseLabels || false}>
      <div className="colorMapRamp">
        <svg width="40" height={height}>
          <linearGradient id="jet_r" x1="0" x2="0" y1="0" y2="1">
            {colorMap.entries.map((entry, i) => (
              <stop key={i} offset={(i / (colorMap.entries.length - 1)) * 100 + "%  "} stopColor={entry.color}></stop>
            ))}
          </linearGradient>

          <rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width="40"
            // height={18 * colorMap.entries.length}
            height={height}
            fill="url(#jet_r)"
            id="legendOriginal"
          ></rect>
        </svg>

        <div className="colorMapEntries">
          {colorMap.entries.map((entry, i) => (
            <div key={i} className="colorMapEntry" style={{ height: height / colorMap.entries.length }}>
              <div className="colorMapEntryLabel">
                {i === 0 && "<"}
                {i === colorMap.entries.length - 1 && ">"}
                {entry.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Styles>
  );
};

LegendRamp.defaultProps = defaultProps;
export { LegendRamp };

const Styles = styled.div<{ reverse: boolean }>`
  position: relative;
  display: block;

  .colorMapRamp {
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  }
  .colorMapEntries {
    display: flex;
    flex-direction: column;
  }
  .colorMapEntry {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 18px;
  }
  .colorMapEntryLabel {
    color: #242a38;
    padding-left: ${({ theme }) => `${theme.spacing(0.5)}px`};
    padding-right: ${({ theme }) => `${theme.spacing(0.5)}px`};
  }
`;
