import { atom } from "jotai";
import L, { LatLngBounds } from "leaflet";
import wkx from "wkx";
import { BELGIUM_GEOMETRY } from "../config";

function defaultBounds() {
  const geometry = wkx.Geometry.parse(BELGIUM_GEOMETRY);
  return L.geoJSON(geometry.toGeoJSON() as any).getBounds();
}

export const boundsAtom = atom<LatLngBounds | null>(defaultBounds());
