import { useMemo } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import styled from "styled-components";
import { ExtraInfoOverlayer } from "../types";
import { sortBy } from "lodash";
import { CATEGORY_ORDER } from "../config/overlayers/extra-info-overlayers";

interface Props {
  layers: ExtraInfoOverlayer[];
  onChange: (layer: ExtraInfoOverlayer, checked: boolean) => void;
}

export function ExtraInfoLayersList(props: Props) {
  const categories = useMemo(() => {
    const categories = props.layers.reduce((groupAcc: Array<ExtraInfoOverlayer["category"]>, layer) => {
      if (groupAcc.includes(layer.category)) return groupAcc;
      else return [...groupAcc, layer.category];
    }, []);
    const sortedCategories = sortBy(categories, (category) => CATEGORY_ORDER.indexOf(category));
    return sortedCategories;
  }, [props.layers]);

  return render();

  function render() {
    return (
      <Styles>
        <div className="category-list">
          {categories.map((category) => (
            <div key={category} className="category-item">
              <Typography variant="h4" gutterBottom>
                {category}
              </Typography>
              {props.layers.filter((layer) => layer.category === category).map(renderLayerItem)}
            </div>
          ))}
        </div>
      </Styles>
    );
  }

  function renderLayerItem(layer: ExtraInfoOverlayer) {
    return (
      <FormControlLabel
        key={layer.key}
        control={
          <Checkbox
            size={"small"}
            color="primary"
            checked={layer.isActive}
            onChange={(event, checked) => {
              props.onChange(layer, checked);
            }}
            name={layer.key}
          />
        }
        label={layer.name}
      />
    );
  }
}

const Styles = styled.div`
  .category-list {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)}px;
  }

  .category-item {
    .MuiTypography-h4 {
      text-transform: capitalize;
      font-size: 14px;
    }
  }
`;
