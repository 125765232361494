import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { spatialOverlayers } from "../../config/overlayers/spatial-overlayers";
import { ComparisonChart } from "./ComparisonChart";
import { MyMapClick } from "../../types";
import { useAtom } from "jotai";
import { activeBelfiusOverlayerAtom } from "../../atoms/overlayers.atom";
import { isLegendRescaledAtom } from "../../atoms/legend.atom";
import { boundsAtom } from "../../atoms/bounds.atom";

interface Props {
  markers: MyMapClick[];
}
export function ComparisonCharts(props: Props) {
  const [bounds] = useAtom(boundsAtom);
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);
  const [isLegendRescaled] = useAtom(isLegendRescaledAtom);

  const comparisonOverlayers = spatialOverlayers.filter((overlayer) => {
    return overlayer.includeInComparison;
  });

  return render();

  function render() {
    if (!bounds) return null;

    return (
      <Styles>
        {comparisonOverlayers.map((layer) => {
          return (
            <div key={layer.key} className="item">
              <Typography variant="h3">{layer.name}</Typography>

              <ComparisonChart
                bounds={bounds}
                markers={props.markers}
                layer={layer}
                belfiusOverlayer={activeBelfiusOverlayer}
                isLegendRescaled={isLegendRescaled}
              />
            </div>
          );
        })}
      </Styles>
    );
  }
}

const Styles = styled.div`
  margin-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)}px;
`;
