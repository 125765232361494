import { SpatialOverlayer } from "../../types";

export const spatialOverlayers: SpatialOverlayer[] = [
  {
    key: "walkability_WLK-walkability_v1",
    name: "Walkabilityscore",
    includeInComparison: true,
    isActive: false,
    extraDataLayers: [],
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_WLK-walkability_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-walkability-woongebied_v1",
    name: "Walkabilityscore bebouwd gebied",
    includeInComparison: true,
    isActive: true,
    extraDataLayers: [],
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_WLK-walkability-woongebied_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-stratenconnectiviteit_v1",
    name: "Stratenconnectiviteit",
    isActive: false,
    includeInComparison: true,
    extraDataLayers: [],
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_z-stratenconnectiviteit-VlBr_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-bevolking_v1",
    name: "Woondichtheid",
    isActive: false,
    includeInComparison: true,
    extraDataLayers: [
      {
        layer: "walkability_inwoners-2019-100m-vl-br-anonymised_v1",
        label: "<%= value %> bewoners/ha",
      },
    ],
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_z-woondichtheid-VlBr_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
  {
    key: "walkability_WLK-z-functiemix_v1",
    name: "Functiemix",
    isActive: false,
    includeInComparison: true,
    extraDataLayers: [],
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability_z-functiemix-VlBr_v1",
      format: "image/png",
      tiled: true,
      transparent: true,
      zIndex: 5,
    },
  },
];
