import React from "react";
import styled from "styled-components";
import { Box, Grid, Typography } from "@material-ui/core";

interface Props {
  color: string;
  text: string;
  className?: string;
}

export const LegendItem = (props: Props) => {
  const { color, text } = props;

  return (
    <StyledGrid container direction="row" alignItems="center" color={color} className={props.className}>
      <Box className="box"></Box>
      <Typography>{text}</Typography>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)<{ color: string }>`
  .box {
    background-color: ${({ color }) => `${color}`};
    width: 11px;
    height: 11px;
    margin-right: 11px;
  }
`;
