import Titling from "../assets/fonts/Neutraface2Display/Neutraface2Display-Titling.otf";
import Alegreya from "../assets/fonts/Alegreya_Sans/AlegreyaSans-Regular.ttf";

const TitlingFont = {
  fontFamily: "Neutra Display Titling",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
  local('Neutra Display Titling'),
  local('Neutra Display Titling'),
  url(${Titling}) format('opentype')
`,
};

const AlegreyaFont = {
  fontFamily: "Alegreya",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
  local('Alegreya'),
  local('Alegreya'),
  url(${Alegreya}) format('truetype')
`,
};

export const fontFaces = [TitlingFont, AlegreyaFont];
