import { MyMapClick, BaseOverlayer } from "../types";
import { useQuery, UseQueryOptions } from "react-query";
import { getBelfiusClusterInfo } from "../utils/belfius-clusters.utils";

export const useBelfiusClusterInfo = (
  mapClick: MyMapClick,
  score: number,
  overlayer: BaseOverlayer | null,
  options?: UseQueryOptions<any>
) => {
  return useQuery(
    ["belfius-cluster-info", mapClick, score, overlayer],
    () => getBelfiusClusterInfo(mapClick, score, overlayer),
    options
  );
};
