import { Baselayer } from "../types";

export const baselayers: Baselayer[] = [
  {
    // OpenStreetMap_Mapnik
    key: "street-map",
    title: "Stratenkaart",
    description: "",
    isActive: true,
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: `&copy; <a href='https://osm.org/copyright' rel="noopener noreferrer" target="_blank">OpenStreetMap</a> contributors`,
    image: "images/map-preview/street-map@2x.png",
  },
  {
    // Esri_WorldImagery
    key: "world-imagery",
    title: "Satelliet",
    description: "",
    isActive: false,
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    attribution: `&copy; <a href="https://www.esri.com/en-us/maps-we-love/gallery/satellite-map" rel="noopener noreferrer" target="_blank">Source: Esri, Maxar, Earthstar Geographics, and the GIS User Community</a>`,
    image: "images/map-preview/world-imagery@2x.png",
  },
  {
    // cartoDB
    key: "low-contrast",
    title: "Laag contrast",
    description: "",
    isActive: false,
    url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png",
    attribution: '&copy; <a href="https://carto.com/about-carto/">CartoDB</a>',
    image: "images/map-preview/low-contrast@2x.png",
  },
  {
    key: "grb",
    title: "GRB",
    url: "https://geo.api.vlaanderen.be/GRB/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&LAYER=grb_bsk&STYLE=&FORMAT=image/png&TILEMATRIXSET=GoogleMapsVL&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
    attribution: `&copy; <a href="https://overheid.vlaanderen.be/informatie-vlaanderen/producten-diensten/basiskaart-vlaanderen-grb" rel="noopener noreferrer" target="_blank">Digitaal Vlaanderen</a>`,
    image: "https://mapbase.marvin.vito.be/configurations/spaarbekkens/images/map-preview/GRB.png",
    isActive: false,
  },
  {
    key: "grb-grijs",
    title: "GRB grijs",
    url: "https://geo.api.vlaanderen.be/GRB/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&LAYER=grb_bsk_grijs&STYLE=&FORMAT=image/png&TILEMATRIXSET=GoogleMapsVL&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
    attribution: `&copy; <a href="https://overheid.vlaanderen.be/informatie-vlaanderen/producten-diensten/basiskaart-vlaanderen-grb" rel="noopener noreferrer" target="_blank">Digitaal Vlaanderen</a>`,
    image: "https://mapbase.marvin.vito.be/configurations/spaarbekkens/images/map-preview/GRB_grijs.png",
    isActive: false,
  },
];
