import { RasterQuantiles } from "guppy.ts";
import { ColorMap } from "marvin-ui-kit";
import { maxBy, minBy } from "lodash";

function format(value: number): string {
  return value.toFixed(2);
}

export function colorMapFromQuantiles(quantiles: RasterQuantiles[]): ColorMap {
  return {
    entries: [
      {
        color: "#012EFF",
        quantity: quantiles[4].value,
        label: format(quantiles[4].value),
      },
      {
        color: "#012EFF",
        quantity: quantiles[4].value,
        label: "",
      },
      {
        color: "#13EBF2",
        quantity: quantiles[3].value,
        label: format(quantiles[3].value),
      },
      {
        color: "#13EBF2",
        quantity: quantiles[3].value,
        label: "",
      },
      {
        color: "#FCEF00",
        quantity: quantiles[2].value,
        label: format(quantiles[2].value),
      },
      {
        color: "#FCEF00",
        quantity: quantiles[2].value,
        label: "",
      },
      {
        color: "#EE0400",
        quantity: quantiles[1].value,
        label: format(quantiles[1].value),
      },
      {
        color: "#EE0400",
        quantity: quantiles[1].value,
        label: "",
      },
      {
        color: "#EE0400",
        quantity: quantiles[0].value,
        label: format(quantiles[0].value),
      },
    ],
  };
}

export function positionOnLegend(quantiles: RasterQuantiles[], value: number, size: number): number {
  const lowerBound = maxBy(
    quantiles.filter((quantile) => quantile.value <= value),
    "value"
  );
  const upperBound = minBy(
    quantiles.filter((quantile) => quantile.value >= value),
    "value"
  );

  const lowerBoundValue = lowerBound?.value || upperBound?.value;
  const upperBoundValue = upperBound?.value || lowerBound?.value;

  if (!lowerBoundValue || !upperBoundValue) {
    console.warn("Can't calculate Y position when missing lower or upper bound value");
    return 1;
  }

  const lowerBoundIndex = quantiles.findIndex((quantile) => quantile.value === lowerBoundValue);

  const itemSize = size / (quantiles.length - 1);
  const lowerBoundPosition = itemSize * lowerBoundIndex;

  if (lowerBoundValue === upperBoundValue) {
    if (lowerBoundPosition === 0) return 1;
    return lowerBoundPosition;
  }

  const percentage = getPercentage(lowerBoundValue, upperBoundValue, value);
  const relativePosition = itemSize * (percentage / 100);
  const position = lowerBoundPosition + relativePosition;
  return position;

  function getPercentage(startpos: number, endpos: number, currentpos: number) {
    var distance = endpos - startpos;
    var displacement = currentpos - startpos;
    return (displacement / distance) * 100;
  }
}
