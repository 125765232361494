import { DialogContent, Typography } from "@material-ui/core";
import { InformationDialogTitle } from "../InformationDialog";

export function ExtraOverlayersInformation() {
  return (
    <>
      <InformationDialogTitle>Meer info over de extra kaartlagen</InformationDialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Deze Extra kaartlagen kan je combineren met alle andere kaarten. Ze helpen je om gemeentegrenzen te
          accentueren, kwetsbare voorzieningen zoals kinderopvang, scholen en woonzorgcentra op de kaart te zetten,
          haltes van openbaar vervoer te visualiseren …
        </Typography>
        <Typography variant="body1" paragraph>
          Zo kan je kaarten samenstellen met informatie op maat van jouw buurt en met de focus die jij wil leggen.
        </Typography>
        <Typography variant="body1" paragraph>
          Er zijn twee type kaarten: kaarten die op het ogenblik dat jij ze aanklikt worden opgevraagd en kaarten die op
          voorhand zijn opgeladen in de tool.
          <br />
          De kaarten met het openbaar vervoer zijn kaarten die op het ogenblik dat jij ze aanklikt worden opgevraagd. De
          kleuren en iconen zijn bepaald door de eigenaar van de kaart.
          <br />
          De kaarten met de gemeentegrenzen en kwetsbare voorzieningen zijn speciaal opgeladen in de tool opdat de kaart
          leesbaar is in combinatie met de andere kaarten. Het jaartal waarin de kaart is gemaakt, is toegevoegd. Zo zie
          je in een oogopslag over welk type kaart het gaat.
        </Typography>
        <Typography variant="body1" paragraph>
          Als je een kaart aanvinkt verschijnt er een legende die je kan aan- en uitklikken. Beneden op het scherm
          verschijnt de broninformatie.
        </Typography>
      </DialogContent>
    </>
  );
}
