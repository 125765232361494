import { FormControlLabel, IconButton, Radio, RadioGroup } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import { useAtom } from "jotai";
import styled from "styled-components";

import {
  activeSpatialOverlayerAtom,
  spatialOverlayersAtom,
  updateActiveDemographicOverlayerAtom,
  updateActiveSpatialOverlayerAtom,
} from "../atoms/overlayers.atom";
import { BoxHeader } from "./BoxHeader";

interface Props {
  onClickMoreInformation: () => void;
}

export const WalkabilityIndicatorSelection = (props: Props) => {
  const [spatialOverlayers] = useAtom(spatialOverlayersAtom);
  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);
  const [, setActiveSpatialOverlayer] = useAtom(updateActiveSpatialOverlayerAtom);
  const [, setActiveDemographicOverlayer] = useAtom(updateActiveDemographicOverlayerAtom);

  function updateIndicator(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    if (value === "") setActiveSpatialOverlayer(null);
    else setActiveSpatialOverlayer(value);
    setActiveDemographicOverlayer(null);
  }

  return render();

  function render() {
    return (
      <Styles>
        <RadioGroup
          aria-label="indicators"
          name="indicators"
          value={activeSpatialOverlayer?.key || ""}
          onChange={updateIndicator}
        >
          <BoxHeader>
            Walkabilityscore
            <IconButton onClick={props.onClickMoreInformation}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </BoxHeader>

          <div className="indicators-list">
            <FormControlLabel value="" control={<Radio color="primary" />} label="Geen kaart" />
            {spatialOverlayers.map((overlayer) => (
              <FormControlLabel
                key={overlayer.key}
                value={overlayer.key}
                control={<Radio color="primary" />}
                label={overlayer.name}
              />
            ))}
          </div>
        </RadioGroup>
      </Styles>
    );
  }
};

const Styles = styled.div`
  display: flex;
  flex-direction: column;

  .indicators-list {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
    padding-right: ${({ theme }) => theme.spacing(1)}px;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;
