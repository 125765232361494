import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

export const PageLoader = () => {
  return (
    <Styles>
      <CircularProgress className="progress-bar" size="80px" color="primary" />
    </Styles>
  );
};

const Styles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .progress-bar {
    margin: auto;
  }
`;
