import { createTheme, ThemeOptions, SimplePaletteColorOptions } from "@material-ui/core";
import { fontFaces } from "./fonts";

interface TextColors {
  primary: string;
  secondary: string | undefined;
  disabled: string;
  hint: string;
}

export const theme = ((options: ThemeOptions) => {
  const primary: SimplePaletteColorOptions = {
    light: "#f39c3b",
    main: "#f39c3b",
    dark: "#f39c3b",
    contrastText: "#fff",
  };

  const secondary: SimplePaletteColorOptions = {
    light: "#F8F8F8",
    main: "#F8F8F8",
    dark: "#D2D6D5",
    contrastText: "#000",
  };

  const success: SimplePaletteColorOptions = {
    light: "#c7fc55",
    main: "#93C918",
    dark: "#609800",
    contrastText: "#000",
  };

  const error: SimplePaletteColorOptions = {
    light: "rgba(242, 138, 138, 1)",
    main: "rgba(255, 71, 71, 1)",
    dark: "rgba(148, 0, 0, 1)",
    contrastText: "#fff",
  };

  const warning = secondary;

  const text: TextColors = {
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
  };

  return createTheme({
    mixins: {
      toolbar: {
        minHeight: 60,
      },
    },
    palette: {
      primary,
      secondary,
      text,
      success,
      warning,
      error,
      background: {
        paper: "#FFF",
        default: "#F7F7F7",
      },
    },
    typography: {
      htmlFontSize: 14,
      fontFamily: "Alegreya",
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 800,
      h1: {
        fontWeight: 600,
        fontSize: 26,
        letterSpacing: 0,
        color: "#2c2a2a",
      },
      h2: {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: 0,
        color: "#2c2a2a",
      },
      h3: {
        fontWeight: 600,
        fontSize: 20,
        letterSpacing: 0,
        color: "#2c2a2a",
      },
      h4: {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: 0,
        color: "#2c2a2a",
      },
      h5: {
        fontWeight: 400,
        fontSize: 13,
        letterSpacing: 0,
        color: "#000",
      },
      h6: {
        fontWeight: 300,
        fontSize: 18,
        letterSpacing: 0,
        color: "#000",
      },
      body1: {
        fontSize: 14,
      },
      caption: {
        fontStyle: "italic",
        color: "#333",
      },
    },
    overrides: {
      //@ts-ignore: JSSFontFace doesn't match fontDisplay:string doesn't match fontDispay: swap | auto | ...
      MuiCssBaseline: {
        "@global": {
          "@font-face": fontFaces,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 0,
          marginBottom: "10px",
        },
      },
      MuiRadio: {
        root: {
          color: "#DDE6F0",
        },
      },
      MuiFormControl: {
        root: {
          width: "100%",
        },
      },
      MuiFormControlLabel: {
        root: {
          display: "flex",
          height: "35px",
        },
      },
      MuiListItem: {
        root: {
          "&$selected, &$selected:hover, &$selected:focus": {
            backgroundColor: primary.main,
          },
        },
      },
    },
    ...options,
  });
})({});
