import { Suspense, useState } from "react";
import { MapControl } from "marvin-ui-kit";
import { Box, Fab, Fade, IconButton } from "@material-ui/core";
import { Close as CloseIcon, Room as RoomIcon } from "@material-ui/icons";
import styled from "styled-components";

import { useMarkers } from "../../hooks";
import { MapAnalysisContent } from "./MapAnalysisContent";
import { Spinner } from "../Spinner";
import { OutOfBoundsErrorBoundary } from "../OutOfBoundsErrorBoundary";
import { MapWindowPaper } from "../surface/PaperBackground";
import { CustomMapControlBase } from "./CustomMapControlBase";
import { useAtom } from "jotai";
import { activeSpatialOverlayerAtom } from "../../atoms/overlayers.atom";
import { BoxHeader } from "../BoxHeader";

export const MapAnalysis = () => {
  const markers = useMarkers();
  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);

  const [openWindow, setOpenWindow] = useState<boolean>(true);
  const [firstMarker] = markers;

  function toggleWindow() {
    setOpenWindow(!openWindow);
  }

  return render();

  function render() {
    if (!firstMarker) return null;
    if (!activeSpatialOverlayer) return null;
    if (markers.length > 1) return null;

    return (
      <CustomMapControlBase position="topleft">
        <Styles>
          {openWindow && renderOpen()}
          {!openWindow && renderClosed()}
        </Styles>
      </CustomMapControlBase>
    );
  }

  function renderClosed() {
    return (
      <Fab onClick={toggleWindow} size="medium" color="secondary">
        <RoomIcon fontSize="small" />
      </Fab>
    );
  }

  function renderOpen() {
    if (!activeSpatialOverlayer) return null;

    return (
      <MapControl className="container-open">
        <Fade in={openWindow}>
          <>
            <BoxHeader>
              Analyse-info
              <IconButton size="small" onClick={() => toggleWindow()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </BoxHeader>
            <Box component={MapWindowPaper}>
              <OutOfBoundsErrorBoundary>
                <Suspense fallback={<Spinner className="spinner" />}>
                  <MapAnalysisContent mapClick={firstMarker} overlayer={activeSpatialOverlayer} />
                </Suspense>
              </OutOfBoundsErrorBoundary>
            </Box>
          </>
        </Fade>
      </MapControl>
    );
  }
};

const Styles = styled.div`
  width: 100%;
  overflow: auto;

  .container-open {
    width: 300px;
  }

  .spinner {
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
