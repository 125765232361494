import { useRef } from "react";
import {
  Avatar,
  Box,
  Fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Radio,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Settings as SettingsIcon } from "@material-ui/icons";
import { useAtom } from "jotai";
import { InputRange } from "marvin-ui-kit";
import { NavLink, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { activeBaselayerAtom, updateActiveBaselayerAtom } from "../../atoms/baselayers.atom";
import { baselayers } from "../../config";
import { layerOpacityAtom } from "../../atoms/layerOpacity.atom";

export const ListItemSettings = () => {
  const { activeAction } = useParams<{ activeAction: string }>();
  const history = useHistory();
  const anchor = useRef(null);

  const [layerOpacity, setLayerOpacity] = useAtom(layerOpacityAtom);
  const [activeBaselayer] = useAtom(activeBaselayerAtom);
  const [, updateActiveBaselayer] = useAtom(updateActiveBaselayerAtom);

  function getRoute(route: string) {
    if (history.location.pathname === route) {
      return "/";
    }
    return route;
  }

  return render();

  function render() {
    return (
      <>
        <Tooltip title="Achtergrondkaart">
          <ListItem
            className="list-item"
            selected={activeAction === "settings"}
            button
            component={NavLink}
            ref={anchor}
            to={{ ...history.location, pathname: getRoute(`/settings`) }}
          >
            <SettingsIcon />
          </ListItem>
        </Tooltip>
        <Popper
          open={activeAction === "settings"}
          anchorEl={anchor.current}
          disablePortal={false}
          placement="left-start"
        >
          <Fade in={activeAction === "settings"}>
            {/* <MenuPaper> */}
            <Styles>
              <Paper>
                <Box px={3}>
                  <Typography variant="h3" className="title">
                    Achtergrondkaart
                  </Typography>
                </Box>

                <List>
                  {baselayers.map((baselayer) => (
                    <ListItem
                      button
                      key={baselayer.key}
                      onClick={() => updateActiveBaselayer(baselayer)}
                      selected={activeBaselayer?.key === baselayer.key}
                    >
                      <ListItemAvatar>
                        <Avatar alt={`Preview image of Base layer ${baselayer.title}`} src={baselayer.image} />
                      </ListItemAvatar>

                      <ListItemText primary={baselayer.title} secondary={baselayer.description} />

                      <ListItemSecondaryAction>
                        <Radio
                          edge="start"
                          color="primary"
                          onClick={() => updateActiveBaselayer(baselayer)}
                          checked={baselayer.key === activeBaselayer?.key}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>

                <Box px={3} pt={2}>
                  <Typography variant="h3" className="title">
                    Transparantie Walkabilityscore
                  </Typography>
                  <InputRange
                    value={layerOpacity * 100}
                    min={0}
                    max={100}
                    step={5}
                    onChange={(val) => {
                      setLayerOpacity(val / 100);
                    }}
                  />
                </Box>
              </Paper>
            </Styles>
            {/* </MenuPaper> */}
          </Fade>
        </Popper>
      </>
    );
  }
};

const Styles = styled.div`
  .MuiPaper-root {
    padding: ${({ theme }) => theme.spacing(3, 0)};
  }

  .MuiListItem-root.Mui-selected {
    background: rgba(0, 0, 0, 0.08);
  }
`;
