import { Raster, RasterQuantiles } from "guppy.ts";

export function getQuantileLabel(quantiles: RasterQuantiles[], value: number): string {
  if (!value) {
    return "/";
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [p0, p25, p50, p75, p100] = quantiles;

  if (value < p25.value) return "Laag";
  if (value >= p25.value && value < p50.value) return "Benedengemiddeld";
  if (value >= p50.value && value < p75.value) return "Bovengemiddeld";
  if (value >= p75.value) return "Hoog";
  else return "//";
}

export function quantilesWithAbsoluteMinMax(rasterDetail: Raster, quantiles: RasterQuantiles[]) {
  return [
    { quantile: 0, value: rasterDetail.globalMin || 0 },
    quantiles[1],
    quantiles[2],
    quantiles[3],
    { quantile: 1, value: rasterDetail.globalMax || 99999 },
  ];
}
