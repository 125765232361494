import { IconButton, MenuItem, Select } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import { useAtom } from "jotai";
import React from "react";
import styled from "styled-components";
import {
  activeBelfiusOverlayerAtom,
  belfiusOverlayersAtom,
  updateActiveBelfiusOverlayerAtom,
} from "../atoms/overlayers.atom";
import { BoxHeader } from "./BoxHeader";

interface Props {
  onClickMoreInformation: () => void;
}

export function BelfiusClustersSelection(props: Props) {
  const [belfiusOverlayers] = useAtom(belfiusOverlayersAtom);
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);
  const [, updateBelfiusOverlayer] = useAtom(updateActiveBelfiusOverlayerAtom);

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    updateBelfiusOverlayer(event.target.value as string);
  }

  return (
    <Styles>
      <BoxHeader>
        Belfius-clusters
        <IconButton onClick={props.onClickMoreInformation}>
          <InfoOutlinedIcon fontSize="small" />
        </IconButton>
      </BoxHeader>
      <div className="main">
        <Select
          variant="outlined"
          value={activeBelfiusOverlayer?.key || ""}
          displayEmpty
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="">Maak een keuze</MenuItem>

          {belfiusOverlayers.map((value) => (
            <MenuItem key={value.key} value={value.key}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  .main {
    padding: ${({ theme }) => theme.spacing(2, 2, 1, 2)};
    background-color: ${({ theme }) => `${theme.palette.background.paper}`};
  }
`;
