import { MutableRefObject, Suspense } from "react";
import { useAtom } from "jotai";
import { Drawer, IconButton, Portal, Toolbar, Typography } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router";

import { ComparisonCharts } from "../analyse/ComparisonCharts";
import { Spinner } from "../Spinner";
import { OutOfBoundsErrorBoundary } from "../OutOfBoundsErrorBoundary";
import { PanePaper } from "../surface/PaperBackground";
import { MarkerDetails } from "../analyse/MarkerDetails";
import { useMarkers } from "../../hooks";
import { activeSpatialOverlayerAtom } from "../../atoms/overlayers.atom";
import { HistoryState } from "../../types";

interface Props {
  paneContainer: MutableRefObject<HTMLDivElement | null>;
}

export const AnalysePane = (props: Props) => {
  const { paneContainer } = props;

  const history = useHistory<HistoryState>();
  const location = useLocation<HistoryState>();

  const markers = useMarkers();
  const { activeAction } = useParams<{ activeAction: string }>();
  const [activeSpatialOverlayer] = useAtom(activeSpatialOverlayerAtom);

  if (activeAction !== "analyse" || !activeSpatialOverlayer) {
    return null;
  }

  function showMoreInformation() {
    history.push(location.pathname, { informationDialogSubject: "analyse" });
  }

  return render();

  function render() {
    return (
      <Portal container={paneContainer.current}>
        <StyledDrawer
          style={{ zIndex: 9999 }}
          variant="persistent"
          open={true}
          elevation={12}
          anchor="right"
          PaperProps={{ component: PanePaper }}
        >
          <Toolbar />
          <Suspense fallback={<Spinner />}>
            <Typography variant="h3">
              Start een analyse
              <IconButton onClick={showMoreInformation}>
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Typography>

            <Typography variant="body1">
              Klik op een punt op de kaart om een analyse te starten. Selecteer meerdere punten om plaatsen te
              vergelijken
            </Typography>

            {markers.length > 0 && (
              <>
                <div className="marker-details">
                  {markers.map((marker, index) => (
                    <Suspense key={[marker.latLng.toString(), index].join("-")} fallback="laden...">
                      <MarkerDetails key={index} marker={marker} index={index} />
                    </Suspense>
                  ))}
                </div>

                <OutOfBoundsErrorBoundary>
                  <ComparisonCharts markers={markers} />
                </OutOfBoundsErrorBoundary>
              </>
            )}
          </Suspense>
        </StyledDrawer>
      </Portal>
    );
  }
};

const StyledDrawer = styled(Drawer)`
  width: 330px;
  overflow: visible;

  .marker-details {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)}px;
    padding: ${({ theme }) => theme.spacing(1, 0, 3, 0)};
  }
`;
