import { useLocation } from "react-router-dom";
import { MapPosition } from "../types";

const DEFAULT_INITIAL_POSITION = process.env.REACT_APP_INITIAL_POSITION || "50,4,0";
const [lat, lng, zoom] = DEFAULT_INITIAL_POSITION.split(",");
const initialPosition: MapPosition = {
  lat: Number(lat),
  lng: Number(lng),
  zoom: Number(zoom),
  type: "map",
};

export function useMapPosition(initial = initialPosition) {
  const queryParams = useLocation().search;
  const params = new URLSearchParams(queryParams);

  return {
    ...initial,
    lat: parseFloat(params.get("lat") || initial.lat.toString()),
    lng: parseFloat(params.get("lon") || initial.lng.toString()),
    zoom: parseFloat(params.get("zoom") || initial.zoom.toString()),
  };
}
