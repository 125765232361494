// import { WMSFeatureInfoParams } from "../services/ogc.service";
import { BaseOverlayer } from "../types";

// export function parseWMSFeatureInfoParams(
//   event: L.LeafletMouseEvent
// ): Pick<WMSFeatureInfoParams, "x" | "y" | "width" | "height" | "bbox"> {
//   // round containerPoints for specific browsers (e.g. Chrome specific version)
//   const x = Math.round(event.containerPoint.x);
//   const y = Math.round(event.containerPoint.y);

//   const { x: width, y: height } = event.target._size;

//   const bounds = event.target.getBounds(); //.toBBoxString();
//   const bbox = `${bounds.getSouthWest().lng},${bounds.getSouthWest().lat},${
//     bounds.getNorthEast().lng
//   },${bounds.getNorthEast().lat}`;

//   return { x, y, width, height, bbox };
// }

export function legendGraphicUrl(layer: BaseOverlayer): string {
  const params = new URLSearchParams();
  params.set("version", "1.1.0");
  params.set("request", "GetLegendGraphic");
  params.set("layer", layer.wmsLayer.layers);
  params.set("format", "image/png");

  params.set("style", layer.wmsLayer.styles || "");

  return `${layer.wmsLayer.url}?${params.toString()}`;
}
