import { Suspense, useMemo } from "react";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";
import { Box, Grid, Typography } from "@material-ui/core";
import { RasterQuantiles } from "guppy.ts";
import { useAtom } from "jotai";
import { first, last } from "lodash-es";

import { useRasterQuantiles } from "../../hooks";
import { LegendRamp } from "../LegendRamp";
import { LegendItem } from "./MapLegendItem";
import { BaseOverlayer } from "../../types";
import { BELGIUM_GEOMETRY, QUANTILE_BINS } from "../../config";
import { maxBy, minBy } from "lodash-es";
import { LatLngBounds } from "leaflet";
import { boundsAtom } from "../../atoms/bounds.atom";
import { latLngBoundsToWkt } from "../../utils/geometry.utils";
import { colorMapFromQuantiles } from "../../utils/legend.utils";
import { activeBelfiusOverlayerAtom } from "../../atoms/overlayers.atom";
import { isLegendRescaledAtom } from "../../atoms/legend.atom";
import { useRaster } from "../../hooks/useRaster";
import { quantilesWithAbsoluteMinMax } from "../../utils/quantile.utils";
import { useBelfiusQuantiles } from "../../hooks/useBelfiusQuantiles";

const RAMP_HEIGHT = 150;

interface Props {
  overlayer: BaseOverlayer;
}

export function MapIntervalLegend(props: Props) {
  const { overlayer } = props;
  const [rescaledBounds] = useAtom(boundsAtom);
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);
  const [isLegendRescaled] = useAtom(isLegendRescaledAtom);
  const { data: rasterDetails } = useRaster(overlayer.wmsLayer.layers);
  const { data: quantiles } = useRasterQuantiles(overlayer, QUANTILE_BINS, BELGIUM_GEOMETRY);

  function getExtraInfoLabel() {
    // if the legend is explicity rescale=true
    if (isLegendRescaled) {
      // show rescaled on the active BelfiusCluster
      if (activeBelfiusOverlayer && activeBelfiusOverlayer.key !== "none") {
        return "Inkleuring op basis van de geselecteerde Belfius cluster";
      }
      // or rescale based on the bound area
      else {
        return "Inkleuring op basis van het herschaald gebied";
      }
    }
    // else render based on the full map min/max, p25, p50, p75
    else {
      return "Inkleuring op basis van de data in Vlaanderen en Brussel (BHG)";
    }
  }

  return render();

  function render() {
    if (!rasterDetails) return null;
    if (!quantiles) return null;

    const colorMapQuantiles = quantilesWithAbsoluteMinMax(rasterDetails, quantiles);
    const colorMap = colorMapFromQuantiles(colorMapQuantiles);

    return (
      <Styles>
        <Grid container className="main-container">
          <Box width={113}>
            <Typography variant="body1" className="ellipsis">
              Vlaanderen en BHG
            </Typography>
            <LegendRamp colorMap={colorMap} reverseLabels={true} height={RAMP_HEIGHT} />
          </Box>

          {isLegendRescaled && rescaledBounds && (
            <Suspense fallback={<Skeleton variant="rect" height={180} width={125} style={{ marginLeft: 100 }} />}>
              <RescaledLegend
                overlayer={overlayer}
                originalQuantiles={quantiles}
                bounds={rescaledBounds}
                belfiusOverlayerKey={activeBelfiusOverlayer?.key || ""}
              />
            </Suspense>
          )}
        </Grid>

        <Grid container className="legend">
          <LegendItem className="legend-item" color="#012EFF" text="Hoog" />
          <LegendItem className="legend-item" color="#13EBF2" text="Bovengemiddeld" />
          <LegendItem className="legend-item" color="#FCEF00" text="Benedengemiddeld" />
          <LegendItem className="legend-item" color="#EE0400" text="Laag" />
        </Grid>
        <Typography variant="body1" className="ellipsis">
          {getExtraInfoLabel()}
        </Typography>
      </Styles>
    );
  }
}

function calculateYPositionLegend(quantiles: RasterQuantiles[], value: number, height: number) {
  const reversedQuantiles = [...quantiles].reverse();
  const lowerBound = maxBy(
    reversedQuantiles.filter((quantile) => quantile.value <= value),
    "value"
  );
  const upperBound = minBy(
    reversedQuantiles.filter((quantile) => quantile.value >= value),
    "value"
  );

  const lowerBoundValue = lowerBound?.value || upperBound?.value;
  const upperBoundValue = upperBound?.value || lowerBound?.value;

  if (!lowerBoundValue || !upperBoundValue) {
    console.warn("Can't calculate Y position when missing lower or upper bound value");
    return 1;
  }

  const upperBoundIndex = reversedQuantiles.findIndex((quantile) => quantile.value === upperBoundValue);

  const itemHeight = height / (reversedQuantiles.length - 1);
  const upperBoundPosition = itemHeight * upperBoundIndex;

  if (lowerBoundValue === upperBoundValue) {
    if (upperBoundPosition === 0) return 1;
    return upperBoundPosition;
  }

  const percentage = getPercentage(upperBoundValue, lowerBoundValue, value);
  const relativePosition = itemHeight * (percentage / 100);
  const position = upperBoundPosition + relativePosition;
  return position;

  function getPercentage(startpos: number, endpos: number, currentpos: number) {
    var distance = endpos - startpos;
    var displacement = currentpos - startpos;
    return (displacement / distance) * 100;
  }
}

const Styles = styled.div`
  .main-container {
    height: 180px;
  }

  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .legend {
    display: flex;
    flex-flow: column wrap;
    height: 40px;

    .legend-item {
      width: 50%;
      height: 20px;
    }
  }
`;

function RescaledLegend(props: {
  overlayer: BaseOverlayer;
  bounds: LatLngBounds;
  belfiusOverlayerKey: BaseOverlayer["key"];
  originalQuantiles: RasterQuantiles[];
}) {
  const [activeBelfiusOverlayer] = useAtom(activeBelfiusOverlayerAtom);

  const { data: belfiusQuantiles } = useBelfiusQuantiles(activeBelfiusOverlayer!, props.overlayer, {
    enabled: !!activeBelfiusOverlayer,
  });
  const { data: rasterQuantiles } = useRasterQuantiles(
    props.overlayer,
    QUANTILE_BINS,
    latLngBoundsToWkt(props.bounds)!
  );

  const quantiles = useMemo(() => {
    if (activeBelfiusOverlayer) return belfiusQuantiles;
    else return rasterQuantiles;
  }, [activeBelfiusOverlayer, belfiusQuantiles, rasterQuantiles]);

  return render();

  function render() {
    if (!quantiles) return null;

    const colorMap = colorMapFromQuantiles(quantiles);

    return (
      <>
        <Box width={100}>
          <Typography variant="body1">&nbsp;</Typography>
          <svg width="100" height={RAMP_HEIGHT + 1} version="1.1" xmlns="http://www.w3.org/2000/svg">
            <line
              x1="0"
              y1={calculateYPositionLegend(props.originalQuantiles, last(quantiles)!.value, RAMP_HEIGHT)}
              x2="100"
              y2="0"
              stroke="#000"
              strokeWidth="1"
              id="legendRescaleTopLine"
            />
            <line
              x1="0"
              y1={calculateYPositionLegend(props.originalQuantiles, first(quantiles)!.value, RAMP_HEIGHT - 1)}
              x2="100"
              y2={RAMP_HEIGHT - 1}
              stroke="#000"
              strokeWidth="1"
              id="legendRescaleBottomLine"
            />
          </svg>
        </Box>

        <Box width={125}>
          <Typography variant="body1" className="ellipsis">
            {props.overlayer.name || "Herschaald gebied"}
          </Typography>
          <LegendRamp colorMap={colorMap} height={RAMP_HEIGHT} />
        </Box>
      </>
    );
  }
}
