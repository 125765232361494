import { ListItem, Tooltip } from "@material-ui/core";
import { Layers as LayersIcon } from "@material-ui/icons";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";

export const ListItemLayers = () => {
  const { activeAction } = useParams<{ activeAction: string }>();
  const history = useHistory();

  function getRoute(route: string) {
    if (history.location.pathname === route) {
      return "/";
    }
    return route;
  }

  return (
    <Tooltip title="Extra kaartlagen">
      <ListItem
        className="list-item"
        selected={activeAction === "extra-layers"}
        component={NavLink}
        to={{ ...history.location, pathname: getRoute(`/extra-layers`) }}
        button
      >
        <LayersIcon />
      </ListItem>
    </Tooltip>
  );
};
