import { useRef, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { DashboardPage } from "./DashboardPage";
import { AppLayout } from "../layout/AppLayout";
import { DownloadFileBlob } from "../components/DownloadFileBlob";
import { InformationDialog } from "../components/InformationDialog";

export function RootPage() {
  const actionBarContainer = useRef<HTMLDivElement | null>(null);
  const paneContainer = useRef<HTMLDivElement | null>(null);

  return (
    <AppLayout actionBarRef={actionBarContainer} paneRef={paneContainer}>
      <Suspense fallback={"...loading"}>
        <Switch>
          <Route path="/:activeAction?">
            <DashboardPage actionBarContainer={actionBarContainer} paneContainer={paneContainer} />
          </Route>
          <Redirect to="/" />
        </Switch>

        <DownloadFileBlob />
        <InformationDialog />
      </Suspense>
    </AppLayout>
  );
}
