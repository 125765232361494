import { atom, Getter, Setter } from "jotai";
import { Baselayer } from "../types";
import { baselayers } from "../config/baselayers";

export const baselayersAtom = atom<Baselayer[]>(baselayers);

export const activeBaselayerAtom = atom((get) => get(baselayersAtom).find((o) => o.isActive));

export const updateActiveBaselayerAtom = atom(null, (get: Getter, set: Setter, update: Baselayer) => {
  set(
    baselayersAtom,
    get(baselayersAtom).map((baselayer) => {
      if (baselayer.key === update.key) return { ...baselayer, isActive: true };
      else return { ...baselayer, isActive: false };
    })
  );
});
