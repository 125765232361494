import React from "react";
import { ListItem, Tooltip } from "@material-ui/core";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { ZoneDistanceIcon } from "../../assets/custon-icons/ZoneDistanceIcon";

export const ListItemZoneDistance = () => {
  const { activeAction } = useParams<{ activeAction: string }>();
  const history = useHistory();

  function getRoute(route: string) {
    if (history.location.pathname === route) {
      return "/";
    }
    return route;
  }

  return (
    <Tooltip title="Wandel- en fietsbereik">
      <ListItem
        className="list-item"
        selected={activeAction === "zone"}
        component={NavLink}
        to={{ ...history.location, pathname: getRoute(`/zone`) }}
        button
      >
        <ZoneDistanceIcon />
      </ListItem>
    </Tooltip>
  );
};
