import { useMap } from "react-leaflet";
import { useParams } from "react-router";
import { formatMarkerArray, getMarkersArray } from "../utils/map.utils";
import { useQueryParams } from "./useQueryParams";

export const useMarkers = () => {
  const markers = useQueryParams("markers");
  const { activeAction } = useParams<{ activeAction: string }>();
  const map = useMap();
  const latLngArray = formatMarkerArray(markers);
  const markersArray = getMarkersArray(latLngArray, map);

  if (markersArray.length === 0) return [];
  if (activeAction !== "analyse" && markersArray.length > 0) {
    const [firstMarker] = markersArray;
    return [firstMarker];
  } else return markersArray;
};
