import { AppBar, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/images/logo-gezond-leven.svg";

export function Header() {
  return (
    <StyledAppBar position="fixed" elevation={0}>
      <Toolbar className="toolbar">
        <Logo className="logo" />
        <Typography className="title" variant="h1">
          Walkabilityscore-tool
        </Typography>
      </Toolbar>
    </StyledAppBar>
  );
}

const StyledAppBar = styled(AppBar)`
  z-index: ${({ theme }) => `${theme.zIndex.drawer + 1}`};

  .toolbar {
    padding: 0;
  }

  .logo {
    min-height: ${({ theme }) => `${theme.mixins.toolbar.minHeight}px`};
    max-height: ${({ theme }) => `${theme.mixins.toolbar.minHeight}px`};
    padding: ${({ theme }) => `${theme.spacing(1, 1)}`};
    background: ${({ theme }) => `${theme.palette.background.paper}`};
  }

  .title {
    padding: ${({ theme }) => theme.spacing(0, 2)};
    font-family: Neutra Display Titling;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0;
    color: #2c2a2a;
    text-transform: uppercase;
  }
`;
