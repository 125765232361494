import { BelfiusOverlayer } from "../types";

export const belfiusOverlayers: BelfiusOverlayer[] = [
  {
    key: "walkability-v2:BelfiusClusters-V1",
    cluster: "V1",
    name: "Gemeenten in de stadsrand met hoge inkomens en vergrijzende bevolking",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V1'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V2",
    cluster: "V2",
    name: "Gemeenten in de stadsrand met hogere  inkomens en toenemend aantal jongeren",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V2'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V3",
    cluster: "V3",
    name: "Woongemeenten met hogere inkomens",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V3'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V4",
    cluster: "V4",
    name: "Woongemeenten met toenemend aantal jongeren",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V4'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V5",
    cluster: "V5",
    name: "Woongemeenten met vergrijzende bevolking",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V5'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V6",
    cluster: "V6",
    name: "Landelijke woongemeenten met hogere inkomens",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V6'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V7",
    cluster: "V7",
    name: "Landbouwgemeenten",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V7'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V8",
    cluster: "V8",
    name: "Landelijke woongemeenten met eerder vergrijzende bevolking",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V8'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V9",
    cluster: "V9",
    name: "Gemeenten in de stadsrand met economische activiteit en toenemend aantal jongeren",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V9'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V10",
    cluster: "V10",
    name: "Gemeenten met economische activiteit en vergrijzende bevolking",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V10'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V11",
    cluster: "V11",
    name: "Gemeenten met groter bevolkingsaantal en economische activiteit",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V11'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V12",
    cluster: "V12",
    name: "Gemeenten en kleine steden met centrumfunctie  en economische activiteit",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V12'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V13",
    cluster: "V13",
    name: "Goeduitgeruste gemeenten en kleine steden met vergrijzende bevolking",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V13'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V14",
    cluster: "V14",
    name: "Goeduitgeruste gemeenten en kleine steden met toenemend aantal jongeren",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V14'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V15",
    cluster: "V15",
    name: "Grote en regionale steden",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V15'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V16",
    cluster: "V16",
    name: "Kustgemeenten",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'V16'",
    },
  },
  {
    key: "walkability-v2:BelfiusClusters-V17",
    cluster: "BRUSSELS",
    name: "Brussels Hoofdstedelijk Gewest",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "walkability-v2:BelfiusClusters",
      format: "image/png",
      transparent: true,
      zIndex: 15,
      cql_filter: "cluster NOT LIKE 'Brussels'",
    },
  },
].sort((a, b) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
});
