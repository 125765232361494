import { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

export const MapScale = () => {
  const map = useMap();

  useEffect(() => {
    L.control.scale({ imperial: false }).addTo(map);
  }, [map]);

  return null;
};
