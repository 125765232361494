import React, { SVGProps } from "react";

export const AnalyseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.932 512" {...props}>
    <g id="Group_1" data-name="Group 1" transform="translate(-682.068 -284)">
      <path
        id="map-marker-alt"
        d="M118.291,344.482C18.52,199.843,0,185,0,131.841a131.841,131.841,0,0,1,263.682,0c0,53.157-18.52,68-118.291,212.641a16.487,16.487,0,0,1-27.1,0Zm13.549-157.708a54.934,54.934,0,1,0-54.934-54.934A54.933,54.933,0,0,0,131.841,186.775Z"
        transform="translate(682.068 404.597)"
        fill="#747474"
      />
      <path
        id="map-marker-alt-2"
        data-name="map-marker-alt"
        d="M172.268,501.67C26.97,291.031,0,269.413,0,192,0,85.961,85.961,0,192,0S384,85.961,384,192c0,77.413-26.97,99.031-172.268,309.67a24.011,24.011,0,0,1-39.464,0ZM192,272a80,80,0,1,0-80-80A80,80,0,0,0,192,272Z"
        transform="translate(768 284)"
      />
    </g>
  </svg>
);
