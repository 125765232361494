import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

interface Props {
  className?: string;
}

export const Spinner = (props: Props) => {
  const { className } = props;

  return (
    <Styles className={className}>
      <CircularProgress className="progress-bar" size="40px" color="primary" />
    </Styles>
  );
};

const Styles = styled.div`
  margin: auto;
  width: 100%;
  text-align: center;
`;
