import { useState } from "react";
import { MapControl } from "marvin-ui-kit";
import styled from "styled-components";
import { Fab, Grid, IconButton } from "@material-ui/core";
import { Close as CloseIcon, List as ListIcon } from "@material-ui/icons";

import { MapWindowPaper } from "../surface/PaperBackground";
import { CustomMapControlBase } from "./CustomMapControlBase";
import { Spacer } from "../Spacer";
import { useAtom } from "jotai";
import { activeDemographicOverlayerAtom } from "../../atoms/overlayers.atom";
import { legendGraphicUrl } from "../../utils/ogc.utils";
import { BoxHeader } from "../BoxHeader";

export const DemographicOverlayerLegend = () => {
  const [layer] = useAtom(activeDemographicOverlayerAtom);

  const [showLegend, setShowLegend] = useState<boolean>(true);

  function toggleLegend() {
    setShowLegend(!showLegend);
  }

  return render();

  function render() {
    if (!layer) return null;

    return (
      <CustomMapControlBase position="bottomleft">
        <Styles>
          {showLegend && renderOpen()}
          {!showLegend && renderClosed()}
        </Styles>
      </CustomMapControlBase>
    );

    function renderOpen() {
      if (!layer) return null;

      return (
        <MapControl>
          <BoxHeader>
            Legende
            <Spacer />
            <IconButton onClick={toggleLegend}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </BoxHeader>
          <Grid container component={MapWindowPaper} square direction="row">
            <div className="legend-content">
              <img src={legendGraphicUrl(layer)} alt={`overlayer legend ${layer.name}`} />
            </div>
          </Grid>
        </MapControl>
      );
    }
    function renderClosed() {
      return (
        <Fab onClick={toggleLegend} size="medium" color="secondary">
          <ListIcon fontSize="small" />
        </Fab>
      );
    }
  }
};

const Styles = styled.div`
  width: fit-content;
`;
