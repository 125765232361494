import { BaseOverlayer } from "../types";
import { useQuery, UseQueryOptions } from "react-query";
import { fetchRasterQuantiles } from "../services/guppy.service";
import { RasterQuantiles } from "guppy.ts";

export const useRasterQuantiles = (
  layer: BaseOverlayer | null,
  quantiles: number[],
  wkt: string,
  options?: UseQueryOptions<any> // TOOD: this could be typed better
) => {
  return useQuery<RasterQuantiles[] | undefined>(
    ["rasterQuantiles", wkt, layer, quantiles],
    () => fetchRasterQuantiles(layer?.wmsLayer.layers, wkt, quantiles),
    options
  );
};
