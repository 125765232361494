import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html {
    box-sizing: border-box;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, #root {
    height: 100%;
  }

  .address-search {
    font: oblique normal 300 13px/19px Avenir LT Std;
    width: 200px;

    label {
      padding-left: 6px;
      color: #474747;
      opacity: .5;
    }
  }
  .leaflet-marker-icon {
    background-color: transparent;
    border: none;
  }

  .leaflet-popup-content p {
    margin: 0;
  }

  div[role="tooltip"] {
    z-index:9999;
  }
`;
