import { isEmpty } from "lodash-es";
import { toNumber } from "lodash-es";
import { MapPosition } from "./../types/index";

export interface Constants {
  belgiumGeometry: string;
  analyseRoute: "analyse";
  zoneRoute: "zone";
  initialPosition: MapPosition;
}

const [lat, lng, zoom] = process.env.REACT_APP_INITIAL_POSITION!.split(",");

export const BELGIUM_GEOMETRY =
  "POLYGON((2.3562059678239633 51.094149012610586,3.2680712021989633 51.43785725793152,5.234623936573963 51.574621397315376,6.454106358448963 50.949024405670386,6.531010655323963 50.167338553943054,5.849858311573963 49.422704869414254,4.872075108448963 49.4441391249881,2.3562059678239633 51.094149012610586))";

export const INITIAL_POSITION = {
  lat: !isEmpty(lat) ? toNumber(lat) : 51.2195,
  lng: !isEmpty(lng) ? toNumber(lng) : 5.089,
  zoom: !isEmpty(zoom) ? toNumber(zoom) : 14,
  type: "map",
};

export const QUANTILE_BINS = [0, 0.25, 0.5, 0.75, 1];
