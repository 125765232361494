import { FormControlLabel, Radio, RadioGroup, Switch, Typography } from "@material-ui/core";
import styled from "styled-components";
import clsx from "clsx";
import { WalkingDistance, ZoneDistance } from "../types";

export function ZoneDistanceSelect(props: {
  title: string;
  value: number | string;
  zoneDistance: ZoneDistance;
  options: WalkingDistance[];
  onToggleActive: () => void;
  onChangeDistance: (distance: number) => void;
}) {
  return render();

  function render() {
    return (
      <StyledRadioGroup
        name="indicators"
        value={props.value}
        onChange={(event) => props.onChangeDistance(Number(event.target.value))}
      >
        <FormControlLabel
          control={
            <Switch color="primary" checked={props.zoneDistance.isActive} onChange={() => props.onToggleActive()} />
          }
          label={<Typography variant="h4">{props.title}</Typography>}
          labelPlacement="start"
          className="title"
        />
        {props.options.map((option) => (
          <FormControlLabel
            key={option.label}
            className={clsx({ disabled: !props.zoneDistance.isActive })}
            value={option.distance}
            control={<Radio color="primary" />}
            label={option.label}
          />
        ))}
      </StyledRadioGroup>
    );
  }
}

const StyledRadioGroup = styled(RadioGroup)`
  .title {
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 0;
  }
  .disabled {
    .MuiRadio-colorPrimary.Mui-checked {
      color: ${({ theme }) => theme.palette.grey["400"]};
    }
  }
`;
