import { MutableRefObject } from "react";
import { useAtom } from "jotai";
import { Drawer, Portal, Toolbar, Typography, IconButton } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router";

import { ExtraInfoOverlayer, HistoryState } from "../../types";
import { PanePaper } from "../surface/PaperBackground";
import { extraInfoOverlayersAtom, updateExtraInfoOverlayerAtom } from "../../atoms/overlayers.atom";
import { ExtraInfoLayersList } from "../ExtraInfoLayersList";

interface Props {
  paneContainer: MutableRefObject<HTMLDivElement | null>;
}

export const ExtraOverlayersPane = (props: Props) => {
  const { paneContainer } = props;

  const history = useHistory<HistoryState>();
  const location = useLocation<HistoryState>();
  const { activeAction } = useParams<{ activeAction: string }>();

  const [extraInfoOverlayers] = useAtom(extraInfoOverlayersAtom);
  const [, updateExtraInfoOverlayer] = useAtom(updateExtraInfoOverlayerAtom);

  /**
   * Functions
   */
  function updateExtraInfoOverlayers(layer: ExtraInfoOverlayer) {
    updateExtraInfoOverlayer({ ...layer, isActive: !layer.isActive });
  }

  function showMoreInformation() {
    history.push(location.pathname, { informationDialogSubject: "extra-overlayers" });
  }

  return render();

  function render() {
    if (activeAction !== "extra-layers") return null;

    return (
      <Portal container={paneContainer.current}>
        <StyledDrawer
          style={{ zIndex: 9999 }}
          variant="persistent"
          open={true}
          elevation={12}
          anchor="right"
          PaperProps={{ component: PanePaper }}
        >
          <Toolbar />
          <Typography variant="h3">
            Extra kaartlagen
            <IconButton onClick={showMoreInformation}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Typography>
          <ExtraInfoLayersList
            layers={extraInfoOverlayers}
            onChange={(layer, _checked) => updateExtraInfoOverlayers(layer)}
          />
        </StyledDrawer>
      </Portal>
    );
  }
};

const StyledDrawer = styled(Drawer)`
  width: 330px;
  overflow: visible;

  .MuiTypography-h3 {
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;
