import { ReactNode } from "react";
import styled from "styled-components";

export function BoxHeader(props: { children: ReactNode }) {
  return <Styles>{props.children}</Styles>;
}

const Styles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  background-color: ${({ theme }) => `${theme.palette.primary.main}`};
  padding-left: ${({ theme }) => theme.spacing(2)}px;

  font-family: Neutra Display Titling;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0;
  color: #2c2a2a;
  text-transform: uppercase;
`;
