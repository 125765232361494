import { DemographicOverlayer } from "../../types";

export const demographicOverlayers: DemographicOverlayer[] = [
  {
    key: "mediaan_imkomen",
    name: "Mediaan inkomen",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "mediaan_inkomen",
      attribution:
        "Toestand in 2019. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "aantal_65plussers",
    name: "Aantal inwoners > 65 jaar",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:aantal_65plussers",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "percentage_65plus",
    name: "Aandeel inwoners > 65 jaar",
    isActive: false,
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:percentage_65plus",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "aantal_0-17jarigen",
    name: "inwoners < 17 jaar",
    isActive: false,
    category: "aantal",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:aantal_0-17jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "percentage_0-17jarigen",
    name: "inwoners < 17 jaar",
    isActive: false,
    category: "aandeel",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:percentage_0-17jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "aantal_0-2jarigen",
    name: "inwoners 0-2 jaar",
    isActive: false,
    category: "aantal",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:aantal_0-2jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "percentage_0-2jarigen",
    name: "inwoners 0-2 jaar",
    isActive: false,
    category: "aandeel",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:percentage_0-2jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "aantal_3-5jarigen",
    name: "inwoners 3-6 jarigen",
    isActive: false,
    category: "aantal",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:aantal_3-5jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "percentage_3-5jarigen",
    name: "inwoners 3-6 jaar",
    isActive: false,
    category: "aandeel",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:percentage_3-5jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "aantal_6-11jarigen",
    name: "inwoners 7-11 jarigen",
    isActive: false,
    category: "aantal",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:aantal_6-11jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "percentage_6-11jarigen",
    name: "inwoners 7-11 jaar",
    isActive: false,
    category: "aandeel",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:percentage_6-11jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "demografische-gegevens:aantal_12-17jarigen",
    name: "inwoners 12-17 jarigen",
    isActive: false,
    category: "aantal",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:aantal_12-17jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
  {
    key: "percentage_12-17jarigen",
    name: "inwoners 12-17 jaar",
    isActive: false,
    category: "aandeel",
    wmsLayer: {
      url: "/geoserver/wms",
      layers: "demografische-gegevens:demografischeGegevensSectoren",
      styles: "demografische-gegevens:percentage_12-17jarigen",
      attribution:
        "Toestand in 2021. Bron: <a href='http://provincies.incijfers.be'  rel='noopener noreferrer' target='_blank'>provincies.incijfers.be</a>",
    },
  },
];
